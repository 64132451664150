import {
  SEARCH_BY_ORGANIZATION_OR_DOCTOR,
  SEARCH_BY_ORGANIZATION_TYPE,
  SEARCH_BY_PATIENT,
  SEARCH_BY_TRACKING_NUMBER
} from './default';

export default [
  {
    gridItem: { xs: 12, md: 4 },
    ...SEARCH_BY_PATIENT
  },
  {
    ...SEARCH_BY_ORGANIZATION_OR_DOCTOR,
    gridItem: { xs: 12, md: 4 }
  },
  {
    ...SEARCH_BY_ORGANIZATION_TYPE,
    gridItem: { xs: 12, md: 4 }
  },
  {
    type: 'group',
    name: 'group_a',
    gridItem: { xs: 12 },
    containerProps: {
      spacing: 2,
      alignItems: 'end',
      justifyContent: 'space-between'
    },
    items: [
      {
        ...SEARCH_BY_TRACKING_NUMBER,
        gridItem: { xs: 4 }
      },
      {
        type: 'keyword_only',
        gridItem: { xs: 4 }
      }
    ]
  },
  {
    type: 'group_inline',
    name: 'group_c',
    items: [
      {
        type: 'item',
        name: 'collapse'
      },
      {
        type: 'item',
        name: 'auto-refresh'
      },
      {
        type: 'item',
        name: 'onhold'
      },
      {
        type: 'item',
        name: 'reset_filters'
      }
    ]
  }
];
