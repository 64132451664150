import React, { useContext } from 'react';
import PatientsFilter from '../../../../components/PatientsFilter';
import Context from './Context';

const RequestFilters = () => {
  const { search, values } = useContext(Context);
  const handleChange = (value, name) => search(name, value);
  return <PatientsFilter handleChange={handleChange} filterValues={values} />;
};

RequestFilters.propTypes = {};

export default RequestFilters;
