export const STATUSES = {
  new: {
    label: 'New'
  },
  in_progress: {
    label: 'In Progress'
  },
  completed: {
    label: 'Completed'
  }
};
