import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class BatchApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  create(data) {
    return this.request({
      method: 'post',
      url: '/batches',
      data
    });
  }

  get({ perPage, page, order, orderBy, status, searchByName }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const url = this.query
      .for('batches')
      .where('status', status)
      .where('search_by_name', searchByName)
      .sort(orderChar + orderBy)
      .params({
        perPage,
        page
      })
      .get();
    return this.request({
      method: 'get',
      url
    });
  }

  show(id) {
    return this.request({
      method: 'get',
      url: `/batches/${id}`
    });
  }

  update(id, data) {
    return this.request({
      method: 'put',
      url: `/batches/${id}`,
      data
    });
  }

  removeCase(id, caseId) {
    return this.request({
      method: 'delete',
      url: `/batches/${id}/remove-case`,
      data: { case_id: caseId }
    });
  }
}
