import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { produce } from 'immer';
import Content from '../../components/Layout/Content';
import RowsIterator from './components/RowsIterator';
import PatientModificationWarning from '../../components/PatientModificationWarning';
import ShipmentDialog from '../../components/Shipment/ShipmentDialog';
import { openDIBS } from '../../helpers/dibs';
import { isCompletedPatient } from '../../helpers';
import { openPatientModificationWarning } from '../../reducers/modals';
import OpenWithExternalApp from '../../components/OpenWithExternalApp';
import { REQUEST, REQUEST_STATUS } from '../../constants';
import Filters from './components/Filters';
import BaseDataTable from '../../components/DataTable';
import styles from './styles';
import mustNotTriggerCollapseToggling from './helpers/mustNotTriggerCollapseToggling';
import withIdleDetector from '../../hooks/withIdleDetector';
import { LAYOUT_BATCH_MODE } from './layout';
import getActions from './helpers/getActions';
import getColumns from './helpers/getColumns';
import {
  FETCH_PATIENTS_PREFIX,
  initialState,
  setPatientsPageLayout
} from '../../reducers/patients';
import { SORT_VALUE as BUNDLE_SHIPMENT_SORT_VALUE } from './components/ShipmentBundling/constants';
import createLoadingSelector from '../../selectors/loading';
import { isShipmentBundlingEnabledSelector } from '../../selectors/patients';

const useStyles = makeStyles(styles);

const REFRESH_INTERVAL = 10; // seconds

const DataTable = ({
  globalParams, // react state
  additionalParams, // local state
  setAdditionalParams,
  handleUpdateData,
  idle,
  layout,
  setLayout
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const refreshTimes = useRef(0);

  const [currentPatient, setCurrentPatient] = useState(null);
  const [selectedApp, setSelectedApp] = useState(null);
  const [batchedCases, setBatchedCases] = useState([]);
  const [collapsed, setCollapsed] = useState([]);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [collapsedAll, setCollapsedAll] = useState(false);

  const rows = useSelector(state => state.patients.items);
  const total = useSelector(state => state.patients.total);
  const isLoading = useSelector(state => createLoadingSelector([FETCH_PATIENTS_PREFIX])(state));
  const isShipmentBundlingEnabled = useSelector(state => isShipmentBundlingEnabledSelector(state));

  const onPatientModificationWarningClose = answer => {
    if (answer) {
      const fileId = currentPatient.ios_file_id;
      if (fileId) {
        dispatch(openDIBS(selectedApp, `open_file/${fileId}`));
      }
    }
  };

  const handleReset = (params = {}) => {
    setBatchedCases([]);
    setAdditionalParams({
      params: {
        ...initialState.globalParams,
        ...initialState.customGlobalParams,
        ...params
      },
      resetPage: true
    });
  };

  const handleSort = useCallback(
    ({ property, direction }) => {
      setAdditionalParams(
        produce(additionalParams, draft => {
          if (!direction) {
            draft.params.order = initialState.globalParams.order;
            draft.params.orderBy = initialState.globalParams.orderBy;
          } else {
            draft.params.order = direction;
            draft.params.orderBy = property;
          }
          draft.resetPage = true;
        })
      );
    },
    [additionalParams, setAdditionalParams]
  );

  const handleFilterChange = (newValues, resetPage = false) => {
    setCollapsed([]);
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params = {
          ...additionalParams.params,
          ...newValues
        };
        draft.resetPage = resetPage;
      })
    );
  };

  // eslint-disable-next-line no-shadow
  const refresh = useCallback(() => {
    refreshTimes.current += 1;
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params = { ...additionalParams.params };
      })
    );
  }, [setAdditionalParams, additionalParams]);

  const isCollapsed = patientId => {
    return collapsed.find(item => patientId === item) !== undefined;
  };

  const toggleCollapse = patientId => {
    if (isCollapsed(patientId)) {
      setCollapsed(collapsed.filter(item => item !== patientId));
    } else {
      setCollapsed([...collapsed, patientId]);
    }
  };

  const rowsPropsProvider = row => {
    const props = {};
    if (isCollapsed(row.id)) {
      props.className = classes.collapsedRow;
    }
    props.onMouseUp = e => {
      if (!mustNotTriggerCollapseToggling(e.target)) {
        toggleCollapse(row.id);
      }
    };
    return props;
  };

  const handleTrackingNumberSearch = trackingNumber => {
    handleReset({
      trackingNumber,
      activeRequestOnly: false,
      requestTypeStatus: [`${REQUEST.PRINT}.${REQUEST_STATUS.COMPLETED}`]
    });
  };

  const externalAppOpener = useMemo(
    () => ({
      CustomComponent: ({ row }) => {
        const handleOpenExternal = (patient, app) => {
          setCurrentPatient(patient);
          setSelectedApp(app);

          if (isCompletedPatient(patient)) {
            dispatch(openPatientModificationWarning('PatientsPage-List'));
            return;
          }

          const fileId = patient.ios_file_id;
          if (fileId) {
            dispatch(openDIBS(app, `open_file/${fileId}`));
          } else if (patient.has_stl_file) {
            dispatch(openDIBS(app, `open_patient/${patient.id}`));
          }
        };

        return (
          <OpenWithExternalApp
            onClick={app => handleOpenExternal(row, app)}
            patient={row}
            buttonProps={{
              name: 'open',
              title: 'Open DIBS',
              className: classes.compactButton,
              disabled: !row.has_ios_file && !row.has_stl_file,
              size: 'small',
              variant: 'contained'
            }}
          />
        );
      },
      // eslint-disable-next-line react/prop-types
      Wrapper: ({ row, children }) => {
        let badgeLabel = null;
        const classNames = [classes.dibsButtonBadge];
        if (row.has_ios_file) {
          badgeLabel = 'IOS';
          classNames.push(classes.dibsButtonBadgeIos);
        } else if (row.has_stl_file) {
          badgeLabel = 'STL';
          classNames.push(classes.dibsButtonBadgeStl);
        }
        return (
          <Badge className={classNames.join(' ')} badgeContent={badgeLabel}>
            {children}
          </Badge>
        );
      }
    }),
    [classes, dispatch]
  );

  useEffect(() => {
    if (!idle || !autoRefresh) {
      refreshTimes.current = 0;
      return () => {};
    }
    if (refreshTimes.current === 0) {
      refresh();
      return () => {};
    }

    const timeout = setTimeout(refresh, REFRESH_INTERVAL * 1000);

    return () => clearTimeout(timeout);
  }, [idle, refresh, autoRefresh]);

  useEffect(() => {
    if (
      globalParams.orderBy === BUNDLE_SHIPMENT_SORT_VALUE &&
      globalParams.orderBy !== additionalParams.params.orderBy
    ) {
      handleSort({
        property: BUNDLE_SHIPMENT_SORT_VALUE,
        direction: 'asc'
      });
    }
  }, [globalParams.orderBy, additionalParams.params.orderBy, handleSort]);

  useEffect(() => {
    if (collapsedAll) {
      setCollapsed([...rows.map(r => r.id)]);
    } else {
      setCollapsed([]);
    }
  }, [rows, collapsedAll, setCollapsed]);

  const actions =
    layout === LAYOUT_BATCH_MODE
      ? []
      : getActions({
          externalAppOpener,
          setCurrentPatient,
          history,
          setSelectedApp,
          dispatch,
          classes,
          collapsed,
          setCollapsed,
          handleTrackingNumberSearch,
          isCollapsed,
          isShipmentBundlingEnabled
        });

  const selectedColumns =
    layout === LAYOUT_BATCH_MODE
      ? ['batching', 'patient', 'requests', 'shipping']
      : ['patient', 'date', 'production_time', 'requests', 'batch', 'shipping'];

  const columns = getColumns(selectedColumns, {
    isCollapsed,
    additionalParams,
    handleTrackingNumberSearch,
    batchedCases,
    setBatchedCases,
    isShipmentBundlingEnabled
  });

  const renderFilters = () => {
    return (
      <>
        <Filters
          values={additionalParams.params}
          onChange={handleFilterChange}
          onReset={handleReset}
          onSort={handleSort}
          layout={layout}
          setLayout={setLayout}
          refresh={refresh}
          autoRefresh={autoRefresh}
          setAutoRefresh={setAutoRefresh}
          collapsedAll={collapsedAll}
          setCollapsedAll={setCollapsedAll}
          batchedCases={batchedCases}
          setBatchedCases={setBatchedCases}
        />
      </>
    );
  };

  return (
    <Content filters={renderFilters()} className={classes.compact}>
      <BaseDataTable
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        total={total}
        updateData={handleUpdateData}
        defaultOrderBy={additionalParams.params.orderBy}
        additionalParams={additionalParams}
        showVerticalLines
        rowsPerPageOptions={[10, 25, 50, 100]}
        defaultRowsPerPage={50}
        customActions={actions}
        globalParams={globalParams}
        onSortChange={handleSort}
        rowPropsProvider={rowsPropsProvider}
        RowsIterator={RowsIterator}
      />
      <PatientModificationWarning
        id="PatientsPage-List"
        onClose={onPatientModificationWarningClose}
      />
      <ShipmentDialog />
    </Content>
  );
};

DataTable.propTypes = {};

export default connect(
  (state, props) => {
    return {
      layout: state.patients.layout
    };
  },
  dispatch => {
    return {
      setLayout: layout => dispatch(setPatientsPageLayout(layout))
    };
  }
)(withIdleDetector(DataTable, REFRESH_INTERVAL));
