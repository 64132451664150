import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class BracketLibrariesApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  getBracketLibraries(organizationId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/bracket-libraries`
    });
  }

  getBracketLibrariesForUser(organizationId, userId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/bracket-libraries`,
      params: {
        member_id: userId,
        visible_only: true
      }
    });
  }

  getBracketLibraryAssignableMembers(organizationId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/bracket-libraries/assignable-members`
    });
  }

  getUploadUrlForBracketLibraries(organizationId, filename) {
    return this.request({
      method: 'post',
      url: `/organizations/${organizationId}/bracket-libraries/request-upload-url`,
      data: { file_name: filename }
    });
  }

  storeBracketLibrary(organizationId, data) {
    return this.request({
      method: 'post',
      url: `/organizations/${organizationId}/bracket-libraries`,
      data
    });
  }

  updateBracketLibrary(organizationId, bracketLibraryId, data) {
    return this.request({
      method: 'put',
      url: `/organizations/${organizationId}/bracket-libraries/${bracketLibraryId}`,
      data
    });
  }

  deleteBracketLibrary(organizationId, itemId) {
    return this.request({
      method: 'delete',
      url: `/organizations/${organizationId}/bracket-libraries/${itemId}`
    });
  }

  getBracketLibraryDownloadUrl(organizationId, itemId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/bracket-libraries/${itemId}/request-download-url`
    });
  }

  getBracketInventory(organizationId, bracketLibraryId) {
    return this.request({
      method: 'get',
      url: `/organizations/${organizationId}/bracket-libraries/${bracketLibraryId}/inventory`
    });
  }

  updateBracketInventory(organizationId, bracketLibraryId, data) {
    return this.request({
      method: 'post',
      url: `/organizations/${organizationId}/bracket-libraries/${bracketLibraryId}/inventory`,
      data
    });
  }
}
