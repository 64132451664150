import React, { useContext } from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { useSelector } from 'react-redux';
import Context from './Context';
import { SORT_VALUE as BUNDLE_SHIPMENT_SORT_VALUE } from '../ShipmentBundling/constants';
import { isShipmentBundlingEnabledSelector } from '../../../../selectors/patients';

const Sorting = () => {
  const { values, onSort } = useContext(Context);
  const isShipmentBundlingEnabled = useSelector(state => isShipmentBundlingEnabledSelector(state));

  const getSortValue = name => {
    return values.orderBy === name ? values.order : null;
  };

  const sortLabel = isShipmentBundlingEnabled ? 'Shipments' : 'Cases';

  const createSortHandler = column => (event, value) => {
    onSort({ property: column, direction: value });
  };

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={getSortValue(isShipmentBundlingEnabled ? BUNDLE_SHIPMENT_SORT_VALUE : 'created_at')}
      exclusive
      onChange={createSortHandler(
        isShipmentBundlingEnabled ? BUNDLE_SHIPMENT_SORT_VALUE : 'created_at'
      )}
    >
      <ToggleButton value="desc">Newest {sortLabel}</ToggleButton>
      <ToggleButton value="asc">Oldest {sortLabel}</ToggleButton>
    </ToggleButtonGroup>
  );
};

Sorting.propTypes = {};

export default Sorting;
