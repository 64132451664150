import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const OnboardingAlert = () => {
  return (
    <Alert severity="warning" variant="outlined">
      <AlertTitle>Heads Up!</AlertTitle>
      You can begin submitting cases immediately after completing the necessary onboarding steps. If
      you need assistance or have questions, please contact customer support at{' '}
      <a href="tel:+18017635039">(801) 763-5039</a> or email us at{' '}
      <a href="mailto:support@dibsai.com">support@dibsai.com</a>.
    </Alert>
  );
};

export default OnboardingAlert;
