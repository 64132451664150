import React from 'react';
import ClickHereLink from './ClickHereLink';

export const STEPS = [
  {
    title: 'Kick Off Call',
    subSteps: [
      {
        title: 'Schedule First Training with Us',
        content: ({ active }) => (
          <>
            During this training we begin by walking you though the very important step of
            digitizing YOUR brackets to be compatible with our DIBS AI software. This starts with
            sending us you bracket information.{' '}
            <ClickHereLink
              disabled={!active}
              href="https://czg1904.na1.hs-sales-engage.com/Ctc/T8+23284/czG1904/Jl22-6qcW7lCdLW6lZ3lqN5q-NsJzvx3-W8wc00z3j21XwW1zMPqy2XzmHXW5fRNsG6pM-yNW4rz8LS7QGDJXW50Cv0S3B-1K4N6BcKNYDXP6bW1VXFsF4NDcxvW6yFs0m8fJZblW8HxjPn5zrdm1MdC7zMQ27KVW95B0Rm50jG6JW5m5kR86mx-gQW6y9Cdk4G-KcrW8rRDY56hRfB4VtCNN95WQnSGW4G7crL1D0nGjW6zL93f1V5XGgW8H9LzD3qcnVvW844L4d6lk6wyW1Qws477B8GjVW5qBSgg2-5wwgW87VyX968-Kx2W44myx14xjH1yf1PRmRR04"
            />{' '}
            to schedule a time to meet with us! This call takes about 15 minutes.
          </>
        )
      },
      {
        title: 'Send Part Numbers to OrthoSelect',
        content: ({ active }) => (
          <>
            We need individual part numbers for each bracket of each set you're sending to us. You
            can{' '}
            <ClickHereLink
              disabled={!active}
              href="https://czg1904.na1.hs-sales-engage.com/Ctc/T8+23284/czG1904/Jl22-6qcW7lCdLW6lZ3kwW3GVkXD3SBbY-W8Hn_bc5JSvJHW1G9FdL5Y9HLQW8h8SS-1XtsvpVWPp3Q4XLgbbW2kHNl87zxv8ZW5nQ7P28-WT9qN3R4hxqBvPlNMK291znSd0HW3R-XDJ4g22vTW76T53x8c7Lq3W3cPj3097PttlW4BpWwc4_h0wXW4g3RBw1tKTq7N4jqhyJYGgTHW1B5Z5P5WH33XN5hss7wtZlhFVSVb7W1PxGldW48qjmz4wDxWxW4XcxHh2cl8jTW1PGK2M77kds4W4Yj6s898WtY7VX_-jv5N2TDgW9jK26N1Z5KKMf554KH004"
            />{' '}
            for the document on how to send them, or you can email the part numbers to our Bracket
            Team, <a href="mailto:brackets@myorthoselect.com">brackets@myorthoselect.com</a>.
          </>
        )
      },
      {
        title: 'Send Physical Brackets to OrthoSelect',
        content: ({ active }) => (
          <>
            You can have your manufacturer ship your brackets directly to us, or send them from your
            office.{' '}
            <ClickHereLink
              disabled={!active}
              href="https://czg1904.na1.hs-sales-engage.com/Ctc/T8+23284/czG1904/Jl22-6qcW7lCdLW6lZ3kwW3GVkXD3SBbY-W8Hn_bc5JSvJHW1G9FdL5Y9HLQW8h8SS-1XtsvpVWPp3Q4XLgbbW2kHNl87zxv8ZW5nQ7P28-WT9qN3R4hxqBvPlNMK291znSd0HW3R-XDJ4g22vTW76T53x8c7Lq3W3cPj3097PttlW4BpWwc4_h0wXW4g3RBw1tKTq7N4jqhyJYGgTHW1B5Z5P5WH33XN5hss7wtZlhFVSVb7W1PxGldW48qjmz4wDxWxW4XcxHh2cl8jTW1PGK2M77kds4W4Yj6s898WtY7VX_-jv5N2TDgW9jK26N1Z5KKMf554KH004"
            />{' '}
            for an easy guide on how to send them.
          </>
        )
      },
      {
        title: 'Bracket Information Received!',
        content: (
          <>
            We've gotten all information needed, the Bracket Team will send you an email with an
            estimated completion date. We have to wait until the brackets are digitized before
            moving onto the next training.
          </>
        )
      }
    ]
  },
  {
    title: 'Case Submission',
    subSteps: [
      {
        title: 'Brackets are Completed!',
        content: ({ active }) => (
          <>
            We're ready to submit your first case!{' '}
            <ClickHereLink
              disabled={!active}
              href="https://czg1904.na1.hs-sales-engage.com/Ctc/T8+23284/czG1904/Jll2-6qcW7Y8-PT6lZ3nwW4w-5cC2sLpCbW1PBPWd4MsMQPW4GzhXR3fZwR4W8_GZSH4YLdDXW7YrxF84SsnHtW6f-6Ss8fmd5lW5NzrLp56dpsvW781WTn6t1_h3VCsVfq63-bkwW96PxH7445vTbW6LdFVT4TDymxW8W8PQx40p6xJW4CGkwc8G7QmlVHlxCs3h4NJBN2-XFtv3M4GTW803FFc4J-RMXW8bcDLf3zR4cJN4h4ZWW3VSBBW5D93r06PS8dYW602fyq6S8hyyVM9Rb84QzxlnW9gVsqL27jqmGVRsRb-44V7ZZW7gRlZ-2KQtJrW7mkYc48Z_w8zW7hhkRQ6g-0sZf9kyyMn04"
            />{' '}
            to schedule a time with us. This takes about 30 minutes.
          </>
        )
      },
      {
        title: 'Have a Patient Ready',
        content: (
          <>
            You don't have to choose a specific type of case to get started. Make sure you have all
            the information for a patient ready to go (STL files and intraoral photos) to submit the
            case with us.
          </>
        )
      },
      {
        title: 'Connect Your Scanner',
        content: ({ active }) => (
          <>
            If you haven't already done so,{' '}
            <ClickHereLink
              disabled={!active}
              href="https://czg1904.na1.hs-sales-engage.com/Ctc/T8+23284/czG1904/JlF2-6qcW8wLKSR6lZ3nDW8_V-6K7bfdKzW5Knqk559y-52W2nDyrC4tVGL1W60s4-X7vgG-8W3TKms95XbNNCW3l9QNR4j-TgFN94T52y5kChCW1hH-FY3FMGGhW3CSnKf4qtPb2W5BDj188LJSWXW2hfZ9_28z--MW2cMVTL4QDf4rVHQ_dN3PBTvwW54bjpX2xXQClN7pQTZ5WT8yRW2QG3811Ls4SqW5C7JrF1z_mS6N6wvFHhgjy42W69T-Cn9byhn1W9l-zF12k335pVTLjls5Zyct_W1-PQVQ5dz8-BW2HgXGQ5fG8g3N8-P3qsQFdBHW4G379-4FDqDgW5fHhLv4_PsgxW253gmw1M151zW4n54NX6X6pcDf8NW2Qq04"
            />{' '}
            for our document on how to connect your intraoral scanner to us, so you can send scans
            directly!
          </>
        )
      },
      {
        title: 'Download the Software',
        content: (
          <>
            You can download the software directly from this website! Go to 'Account &gt;
            Installer', then click the cloud to download, and install to your computer. Please know
            that the software is only available for download onto a PC, and not a MAC.
          </>
        )
      }
    ]
  },
  {
    title: 'Case Approval/Software Training',
    subSteps: [
      {
        title:
          "Now that you've submitted your case, we need to review it with the doctor in the software.",
        content: ({ active }) => (
          <>
            If you haven't already, please{' '}
            <ClickHereLink
              disabled={!active}
              href="https://czg1904.na1.hs-sales-engage.com/Ctc/T8+23284/czG1904/Jl22-6qcW7lCdLW6lZ3mjW35sQM34rQchyW8V1NtW8PMD2lW463LQl3FZTV_W6yTB0J59V11BN6Q4689fLdxjW3jX0jt5y1TKVW3PPLrz5bTy4kW1z0f2m79TcH0W90QJ-p4TDzdyW6NHBQ_9lCf-lV2gHM32qrkCRW5NsWSz8s94hjW6GbTY42ZMqgBW6_cSz83n_Jd5VXJDct6XTRQxW2FMxnd26_wDXW1hhT6y6DnTrjW14cY7C4ZwhByN6j5S9t46zYzW5HnNHC4xZryCW3GgZL38B4hzHW7Qr-FB6gSxFwW4Hl7cB8Q8HDQW5vzskm1dZj8Gf2Yqb5v04"
            />{' '}
            to schedule that with us! It takes about 1 hour.
          </>
        )
      },
      {
        title: 'Case Approved',
        content: (
          <>
            Now that the case has been approved, our team will print those trays and get them
            shipped out to you. We'll mail you a Clinical Training Kit to use during our Clinical
            Training.
          </>
        )
      }
    ]
  },
  {
    title: 'Clinical Training',
    subSteps: [
      {
        title:
          'Our last training goes over our bonding protocols. It is necessary to complete this training, even if you have previous IDB experience! We want everyone on your team that will be present for bondings to join. This training takes about 20-30 minutes.',
        content: ({ active }) => (
          <>
            <ClickHereLink
              disabled={!active}
              href="https://czg1904.na1.hs-sales-engage.com/Ctc/T8+23284/czG1904/Jl22-6qcW7lCdLW6lZ3mFW6SJZb_20svnQW2syHMq349xjpVnCL-R5fmCPbVzv99x1p94_qW1vXBfh6DRyxtW7WVH9x1pfJ0wN7CDGv8hZ_BqW8hZFvw6l2LvBMKmQTXbTlZBN5YTFV5rmlv6W5QzmSS5gdGkBW3F7Z6x3CSY3YW62S8ZN1brJhKN6wRPl7n4_7jW2NVDGs2_kZKJW7rhzhk63cJPrW2PXJgG42dvsfW5TKR3C3Gk6-KW2C11v-8N_029W1wDkk07jtHVMW6kPWPG1c7JFBVn6str6HDCpxW6QblFY5jRQ60W8cf0yh66wCNCf7ql22x04"
            />{' '}
            to schedule that if we haven't scheduled it with you!
          </>
        )
      }
    ]
  }
];

export function getStepIndex(stepNumber) {
  let totalIteratedSteps = 0;
  const data = {
    current: null,
    active: {
      parent: 0,
      child: 0
    }
  };
  STEPS.forEach((step, index) => {
    if (data.current !== null) {
      return;
    }
    if (totalIteratedSteps + step.subSteps.length >= stepNumber) {
      data.current = {
        parent: index,
        child: stepNumber - totalIteratedSteps - 1
      };
      if (data.current.child === step.subSteps.length - 1) {
        data.active = {
          parent: data.current.parent + 1,
          child: 0
        };
      } else {
        data.active = {
          parent: data.current.parent,
          child: data.current.child + 1
        };
      }
    } else {
      totalIteratedSteps += step.subSteps.length;
    }
  });
  return data;
}

export function getActualStepFromIndex(parent, child) {
  let step = 0;
  STEPS.forEach((mainStep, index) => {
    if (index < parent) {
      step += mainStep.subSteps.length;
    } else if (index === parent) {
      step += child + 1;
    }
  });
  return step;
}

export function getTotalSteps() {
  let step = 0;
  STEPS.forEach(mainStep => {
    step += mainStep.subSteps.length;
  });
  return step;
}

const stepLabels = {};

export function getStepLabel(step) {
  if (step === null) {
    return '';
  }
  if (stepLabels[step]) {
    return stepLabels[step];
  }
  const stepIndex = getStepIndex(step);
  stepLabels[step] = `${STEPS[stepIndex.current.parent].title} - ${
    STEPS[stepIndex.current.parent].subSteps[stepIndex.current.child].title
  }`;
  return stepLabels[step];
}
