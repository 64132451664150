import React from 'react';
import PropTypes from 'prop-types';

const ClickHereLink = ({ disabled, href }) => {
  if (disabled) {
    return 'CLICK HERE';
  }

  return (
    <a href={href} target="_blank" rel="noreferrer noopener">
      CLICK HERE
    </a>
  );
};

ClickHereLink.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.string.isRequired
};

ClickHereLink.defaultProps = {
  disabled: false
};

export default ClickHereLink;
