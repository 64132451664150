import Box from '@material-ui/core/Box';
import { ROLES } from '../../../../../constants';
import { VALUE_CONVERSION_MODE_ONLY_WITHOUT } from '../ToggleFilter';

export const SEARCH_BY_PATIENT = {
  type: 'search',
  name: 'searchFullNameQuery',
  placeholder: 'Search by Patient'
};

export const SEARCH_BY_ORGANIZATION_OR_DOCTOR = {
  type: 'search',
  name: 'searchDoctorFullNameOrOrganizationQuery',
  placeholder: 'Search by Doctor or Organization',
  checkDisable: values => values.userId !== null || values.organizationId !== null,
  delay: 250
};

export const SEARCH_BY_ORGANIZATION_TYPE = {
  type: 'search',
  name: 'customerServiceManager',
  placeholder: 'Search by Organization Type'
};

export const SEARCH_BY_TRACKING_NUMBER = {
  type: 'search',
  name: 'trackingNumber',
  placeholder: 'Search by Tracking Number'
};

export default [
  {
    gridItem: { xs: 12, md: 4 },
    ...SEARCH_BY_PATIENT
  },
  {
    ...SEARCH_BY_ORGANIZATION_OR_DOCTOR,
    gridItem: { xs: 12, md: 4 }
  },
  {
    ...SEARCH_BY_ORGANIZATION_TYPE,
    gridItem: { xs: 12, md: 4 }
  },
  {
    type: 'group',
    name: 'group_a',
    gridItem: { xs: 12 },
    containerProps: {
      spacing: 2,
      alignItems: 'end',
      justifyContent: 'space-between'
    },
    items: [
      {
        ...SEARCH_BY_TRACKING_NUMBER,
        gridItem: { xs: 4 }
      },
      {
        type: 'keyword_only',
        gridItem: { xs: 4 }
      }
    ]
  },
  {
    type: 'request_filters',
    name: 'request',
    gridItem: { xs: 12 }
  },
  {
    type: 'group_inline',
    name: 'batch_group',
    gridItem: { xs: 12 },
    ContainerComponent: Box,
    containerProps: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    items: [
      {
        type: 'shipping_date',
        name: 'shipping_date'
      },
      {
        type: 'batch_mode',
        name: 'batch',
        permissions: ['batches.manage']
      }
    ]
  },
  {
    type: 'group_inline',
    name: 'group_b',
    gridItem: { xs: 12 },
    ContainerComponent: Box,
    containerProps: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    items: [
      {
        type: 'lazy',
        callback: ({ userRole }) => {
          if ([ROLES.admin, ROLES.superAdmin].includes(userRole)) {
            return {
              type: 'toggle',
              name: 'withOnhold',
              label: 'On-hold'
            };
          }
          return {
            type: 'toggle',
            name: 'onlyOnhold',
            label: 'On-hold Only',
            valueConversionMode: VALUE_CONVERSION_MODE_ONLY_WITHOUT
          };
        }
      },
      {
        type: 'toggle',
        name: 'activeRequestOnly',
        nullable: true,
        label: 'Active Only'
      },
      {
        type: 'toggle',
        name: 'rushCaseOnly',
        label: 'Rush Case Only'
      },
      {
        type: 'toggle',
        name: 'shipping',
        nullable: true
      },
      {
        type: 'toggle',
        name: 'archived',
        label: 'Archived Only',
        valueConversionMode: VALUE_CONVERSION_MODE_ONLY_WITHOUT
      },
      {
        type: 'toggle',
        name: 'doctorNotesOnly',
        label: 'With Doctor Notes Only'
      },
      {
        type: 'sorting'
      }
    ]
  },
  {
    type: 'group_inline',
    name: 'group_c',
    items: [
      {
        type: 'item',
        name: 'collapse'
      },
      {
        type: 'item',
        name: 'auto-refresh'
      },
      {
        type: 'item',
        name: 'onhold'
      },
      {
        type: 'item',
        name: 'reset_filters'
      }
    ]
  }
];
