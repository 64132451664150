import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { CardActions, Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import React from 'react';
import { connect } from 'react-redux';
import { STATUSES } from '../constants';
import { updateBatchAction } from '../../../reducers/batches';

const UpdateForm = ({ batch, update, updating }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: batch.name,
        status: STATUSES[batch.status].label,
        notes: batch.notes
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name is required'),
        status: Yup.string().required('Status is required'),
        notes: Yup.string().nullable()
      })}
      onSubmit={update}
    >
      {({ isValid, dirty, submitForm }) => (
        <Card style={{ width: '80%' }}>
          <CardHeader title="Batch" />
          <Divider />
          <CardContent>
            <Form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    variant="outlined"
                    fullWidth
                    label="Name"
                    id="name"
                    size="small"
                    name="name"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    variant="outlined"
                    fullWidth
                    label="Status"
                    id="status"
                    name="status"
                    size="small"
                    disabled
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    fullWidth
                    label="Notes"
                    id="notes"
                    size="small"
                    name="notes"
                    component={TextField}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Form>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={submitForm}
              disabled={updating || !isValid || !dirty}
            >
              Update
            </Button>
          </CardActions>
        </Card>
      )}
    </Formik>
  );
};

export default connect(
  state => ({ updating: state.batches.show.updating }),
  (dispatch, props) => {
    return {
      update: data => dispatch(updateBatchAction(props.batch.id, data))
    };
  }
)(UpdateForm);
