import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, CircularProgress, Fade, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { isEmpty } from 'lodash';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { fetchOrganizationAction } from '../../../reducers/organizations';
import CommonPropTypes from '../../../common/PropTypes';
import { getLabel } from '../../../components/Onboarding/helpers';
import { formatHitValue } from '../../../components/Hits/Custom/helpers';
import { findRolesInUser } from '../../../helpers';
import { PAYMENT_STATUS } from '../../../constants';
import phoneNumberFormatter from '../../../helpers/phoneNumberFormatter';
import PatientOnHoldChip from '../../../components/Chips/PatientOnHoldChip';
import Markers from '../components/Markers';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column'
    },
    popupContent: {
      fontSize: theme.typography.pxToRem(11),
      padding: theme.spacing(1),
      borderRadius: theme.shape.border
    },
    rush: {
      fontSize: theme.typography.pxToRem(10),
      marginRight: theme.spacing(-1),
      marginTop: theme.spacing(-1),
      float: 'right',
      color: theme.palette.error.dark,
      paddingLeft: theme.spacing(0.5)
    },
    patientCount: {
      float: 'right',
      fontSize: 11,
      color: theme.palette.text.secondary
    },
    patientNameContainer: {
      display: 'flex'
    },
    preferenceIcon: {
      fontSize: 16,
      marginLeft: theme.spacing(0.5),
      color: theme.palette.success.light
    }
  };
});

const OrganizationInfo = connect(
  (state, { organization }) => {
    const { metadata, fetching } = state.organizations;

    if (!organization) {
      return '';
    }

    return {
      quickGlance: metadata[organization.id] && metadata[organization.id].quick_glance,
      fetching: fetching[organization.id],
      canViewQuickGlance: findRolesInUser(
        ['admin', 'tech_support', 'supervisor', 'super_admin'],
        state.auth.user
      )
    };
  },
  (dispatch, { organization }) => {
    return {
      fetch: () => dispatch(fetchOrganizationAction(organization.id))
    };
  }
)(({ organization, quickGlance, canViewQuickGlance, fetching, fetch }) => {
  const classes = useStyles();

  const [showQuickGlance, setShowQuickGlance] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = e => {
    e.preventDefault();
    if (!canViewQuickGlance) {
      return;
    }
    if (!quickGlance && !fetching) {
      fetch();
    }
    setAnchorEl(e.currentTarget);
    setShowQuickGlance(true);
  };

  const rows = quickGlance
    ? [
        {
          title: 'Account Number',
          value: quickGlance.account_number
        },
        {
          title: 'Joined Date',
          attribute: 'joined_at'
        },
        {
          title: 'Newest Case',
          attribute: 'newest_case_submission'
        },
        {
          title: 'Members',

          value: quickGlance.number_of_users
        },
        {
          title: 'Patients',
          value: quickGlance.number_of_patients
        },
        {
          title: 'Manager (3PP)',
          attribute: 'manager'
        },
        {
          title: 'Payment',
          value: !isEmpty(quickGlance.payment) ? PAYMENT_STATUS[quickGlance.payment].label : 'N/A'
        },
        {
          title: 'Onboarding',
          value: getLabel(quickGlance.onboarding) || 'N/A'
        }
      ]
    : [];

  return (
    <>
      {organization && (
        <Link
          to={`/organizations/${organization.id}`}
          onMouseOver={handleClick}
          onMouseLeave={() => setShowQuickGlance(false)}
        >
          <Typography variant="caption" component="span" color="textSecondary">
            {organization.name}
          </Typography>
        </Link>
      )}
      <Popper open={showQuickGlance} anchorEl={anchorEl} transition placement="right-end">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <TableContainer component={Paper} className={classes.popupContent}>
              {fetching && <CircularProgress size={20} />}
              {!fetching && (
                <Table aria-label="simple table" size="small">
                  <TableBody>
                    {rows.map(({ title, attribute = null, value = null }) => {
                      return (
                        <TableRow key={title}>
                          <TableCell component="th" scope="row">
                            {title}
                          </TableCell>
                          <TableCell align="right">
                            {value !== null
                              ? value
                              : formatHitValue(quickGlance[attribute], attribute)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Fade>
        )}
      </Popper>
    </>
  );
});

const LocationInfo = props => {
  let timezone;
  if (props.timezone_offset) {
    timezone =
      props.timezone_offset > 0 ? `GMT+${props.timezone_offset}` : `GMT${props.timezone_offset}`;
  }

  return (
    <>
      <Typography variant="caption" component="div" color="textSecondary">
        Contact #: {phoneNumberFormatter(props.phone_number)}
      </Typography>
      <Typography variant="caption" component="div" color="textSecondary">
        Country: {props.country} {timezone && `(${timezone})`}
      </Typography>
    </>
  );
};

const PatientColumn = ({ patient, doctor, organization, collapsed }) => {
  const classes = useStyles();

  return (
    <>
      {patient.rx_form !== null && patient.rx_form.rush_case === true && (
        <div className={classes.rush}>
          <i>Rush</i>
        </div>
      )}
      <Tooltip title="Patients count" placement="top">
        <div className={classes.patientCount}>[{doctor.patients_count}]</div>
      </Tooltip>
      <div className={classes.container}>
        <div className={classes.patientNameContainer}>
          <Tooltip
            title={`Dr. ${doctor.last_name} | ${organization ? organization.name : ''}`}
            disableHoverListener={collapsed}
            placement="top"
          >
            <Link to={`/patient-details/${patient.id}`}>
              <Typography variant="body2" component="div" color="primary">
                {patient.full_name}{' '}
                {patient.numeric_suffix !== null && `(${patient.numeric_suffix})`}{' '}
                {patient.deleted_at ? (
                  <Chip color="default" size="small" label="Archived" />
                ) : patient.held_at ? (
                  <PatientOnHoldChip patient={patient} />
                ) : (
                  ''
                )}
              </Typography>
            </Link>
          </Tooltip>

          {patient.rx_form !== null && patient.rx_form.has_root_integration && (
            <Tooltip
              title={
                !patient.rx_form.relu_request ? (
                  'Root Integration'
                ) : (
                  <>
                    Root Integration
                    <br />
                    RELU Job ID: {patient.rx_form.relu_request.id}
                  </>
                )
              }
            >
              <InfoIcon className={classes.preferenceIcon} />
            </Tooltip>
          )}
          {patient.markers && <Markers markers={patient.markers} />}
        </div>
        <div>
          {collapsed && (
            <>
              <Typography variant="caption" component="div" color="textSecondary">
                <Link
                  to={`/patients-page?queryUser=${doctor.id}&queryName=${doctor.full_name}`}
                  replace
                >
                  Dr. {doctor.full_name}
                </Link>
              </Typography>
              {organization && <OrganizationInfo organization={organization} />}
              {patient.rx_form !== null && <LocationInfo {...patient.rx_form.location} />}
              {patient.organization_type !== null && (
                <Typography variant="caption" component="div" color="textSecondary">
                  Org Type: {patient.organization_type}
                </Typography>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

PatientColumn.defaultProps = {
  collapsed: false
};

PatientColumn.propTypes = {
  collapsed: PropTypes.bool,
  patient: CommonPropTypes.patient.isRequired,
  doctor: CommonPropTypes.user.isRequired,
  organization: CommonPropTypes.organization.isRequired
};

export default PatientColumn;
