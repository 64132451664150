import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Badge } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Context from './Context';
import { LAYOUT_BATCH_MODE, LAYOUT_DEFAULT } from '../../layout';
import { createBatchAction } from '../../../../reducers/batches';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

const BatchMode = ({ enabled, batchedCases, setBatchedCases, setLayout, createBatch, refresh }) => {
  const [creating, setCreating] = useState(false);
  const [open, setOpen] = React.useState(false);

  if (!enabled) {
    const startBatchMode = () => {
      setLayout(LAYOUT_BATCH_MODE);
    };

    return (
      <>
        <Button color="primary" title="Start Batching" onClick={startBatchMode} variant="contained">
          Start Batching
        </Button>
      </>
    );
  }

  const exitBatchMode = () => {
    setBatchedCases([]);
    setLayout(LAYOUT_DEFAULT);
  };

  const handleCreateBatch = skipConfirmation => () => {
    if (!skipConfirmation) {
      const confirmationNeeded =
        batchedCases.filter(({ tray_generation_finished }) => !tray_generation_finished).length > 0;
      if (confirmationNeeded) {
        setOpen(true);
        return;
      }
    }

    const patientIds = batchedCases.map(({ id }) => id);
    setCreating(true);
    createBatch(patientIds).then(() => {
      setBatchedCases([]);
      setCreating(false);
      refresh();
    });
  };

  const buttonLabel = creating ? 'Creating' : 'Create Batch';

  const onDialogClose = proceed => {
    if (!proceed) {
      setOpen(false);
      return;
    }
    handleCreateBatch(true)();
  };

  return (
    <>
      <Badge badgeContent={batchedCases.length} color="primary">
        <Button
          disabled={batchedCases.length < 2 || creating}
          color="primary"
          title={buttonLabel}
          onClick={handleCreateBatch(false)}
          variant="contained"
        >
          {buttonLabel}
        </Button>
      </Badge>
      &nbsp;
      <Button
        color="secondary"
        title="Exit Batch Mode"
        onClick={exitBatchMode}
        variant="contained"
        disabled={creating}
      >
        Exit Batch Mode
      </Button>
      <ConfirmationDialog
        open={open}
        handleClose={onDialogClose}
        title="Warning!"
        bodyText={
          "The batch contains patient(s) that haven't finished the tray generation. Do you want to proceed?"
        }
        confirmButtonProps={{ disabled: creating }}
        cancelButtonProps={{ disabled: creating }}
        confirmText="Proceed"
        declineText="Cancel"
      />
    </>
  );
};

BatchMode.propTypes = {
  enabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  batchedCases: PropTypes.array.isRequired,
  setBatchedCases: PropTypes.func.isRequired,
  setLayout: PropTypes.func.isRequired,
  createBatch: PropTypes.func.isRequired
};

export default () => {
  const { layout, setLayout, batchedCases, setBatchedCases, refresh } = useContext(Context);
  const dispatch = useDispatch();

  return (
    <>
      <BatchMode
        batchedCases={batchedCases}
        setBatchedCases={setBatchedCases}
        enabled={layout === LAYOUT_BATCH_MODE}
        setLayout={setLayout}
        refresh={refresh}
        createBatch={(patients, name) => dispatch(createBatchAction(patients, name))}
      />
    </>
  );
};
