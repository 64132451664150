import React from 'react';
import { Form, Formik, Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { resetBracketLibrariesForUsers, updateBracketLibrary } from '../../../../../reducers/bracketLibraries';
import { enqueueNotification } from '../../../../../reducers/notifications';

const HideCheckbox = ({ bracket, organizationId }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values, resetForm, setSubmitting) => {
    dispatch(updateBracketLibrary(organizationId, bracket.id, values))
      .then(() => {
        dispatch(enqueueNotification('success', 'The bracket has been successfully updated.'));
        dispatch(resetBracketLibrariesForUsers(organizationId));
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const validationSchema = Yup.object().shape({
    hide: Yup.boolean()
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          hide: !!bracket.hidden_at
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          handleSubmit(values, resetForm, setSubmitting);
        }}
      >
        {({ isSubmitting, submitForm, setFieldValue }) => (
          <Form>
            <Field name="hide">
              {({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  disabled={isSubmitting}
                  onChange={() => {
                    setFieldValue('hide', !field.value);
                    submitForm();
                  }}
                  color="primary"
                  style={{ zIndex: '1', paddingTop: '12px' }}
                />
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </>
  );
};

HideCheckbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bracket: PropTypes.object.isRequired,
  organizationId: PropTypes.number.isRequired
};

export default HideCheckbox;
