import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles/index';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import {
  filterFilesByType,
  isDoctor,
  isLabPartner,
  isTechnicianOrAdmin,
  UTCDateTimeToLocalDate
} from '../../helpers';
import { FETCH_PATIENT_PREFIX, fetchPatientAction } from '../../reducers/patients';
import { FILE_TYPE_IDS } from '../../constants';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../../components/LoadingIndicator';
import InitialSetupWorkflow from './components/InitialSetupWorkflow';
import DetailItem from '../../components/DetailItem';
import RequestsWorkflowSection from './components/RequestsWorkFlowSection';
import PatientNote from '../../components/PatientNote';
import IOSFileActions from './components/IOSFileActions';
import PatientHoldingAction from '../Patients/components/PatientHoldingAction';
import useOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import PatientWorkflowContext from './context/PatientWorkflowContext';
import Can from '../../components/Can';
import OnHoldNotes from '../../components/OnHoldNotes';
import SubSectionWrapper from '../Account/components/SubSectionWrapper';
import OpenWithExternalApp from '../../components/OpenWithExternalApp';
import PatientOnHoldChip from '../../components/Chips/PatientOnHoldChip';
import hasPermission from '../../selectors/hasPermission';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3)
  },
  patientDetailsHeader: {
    padding: theme.spacing(1)
  },
  patientDetailsContent: {
    padding: theme.spacing(2)
  },
  fullHeight: {
    height: '100%'
  },
  onHoldChip: {
    marginLeft: theme.spacing(1)
  }
}));

const PatientWorkflow = props => {
  const {
    match: { params }
  } = props;
  const patientId = parseInt(params.id, 10);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useSelector(state => state.auth.user);
  const patient = useSelector(state => {
    return (
      state.patients.items[state.patients.items.findIndex(item => item.id === patientId)] || {}
    );
  });

  const canUpdateManagementData = useSelector(
    state =>
      (patient &&
        hasPermission(state, {
          permissions: ['organization.patients.update-management-data'],
          organizationId: patient.organization_id
        })) ||
      hasPermission(state, {
        permissions: ['patients.update', 'patients.manage']
      })
  );

  const isLoadingPatient = useSelector(state =>
    createLoadingSelector([FETCH_PATIENT_PREFIX])(state)
  );

  const { isLoading: isLoadingOrganizationPermissions } = useOrganizationPermissions({
    organizationId: patient.organization_id
  });

  useEffect(() => {
    dispatch(fetchPatientAction(patientId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingPatient || isLoadingOrganizationPermissions) {
    return <LoadingIndicator />;
  }

  if (!patient || !patient.patient_files || !patient.user) {
    return null;
  }

  const patientFiles = patient.patient_files;
  const iosFiles = filterFilesByType(patientFiles, FILE_TYPE_IDS.ios);
  const stlFiles = filterFilesByType(patientFiles, FILE_TYPE_IDS.stl);
  const rxForm = patient.rx_form;

  const iosFile = iosFiles.length ? iosFiles[0] : null;

  const handleShowPatient = id => {
    history.push(`/patient-details/${id}`);
  };

  const handleShowUser = id => {
    history.push(`/users/${id}`);
  };

  const handleShowOrganization = id => {
    history.push(`/organizations/${id}`);
  };

  return (
    <PatientWorkflowContext.Provider value={{ patient }}>
      <Container component="main" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card className={classes.fullHeight}>
              <Grid
                item
                xs={12}
                className={classes.patientDetailsHeader}
                container
                alignItems="center"
              >
                <Typography component="h1" variant="h5">
                  Patient Details
                </Typography>
                <PatientOnHoldChip patient={patient} className={classes.onHoldChip} />
              </Grid>
              <Divider />
              <CardContent className={classes.patientDetailsContent}>
                <Grid container>
                  <DetailItem
                    xs={12}
                    label="Doctor"
                    value={
                      <Can
                        permissions={['users.view', 'users.manage', 'users.manage-limited']}
                        yes={() => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <Link
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              handleShowUser(patient.user_id);
                            }}
                          >
                            <span>{patient.user.full_name}</span>
                          </Link>
                        )}
                        no={() => <span>{patient.user.full_name}</span>}
                      />
                    }
                    noPadding
                  />
                  <DetailItem
                    xs={12}
                    label="Patient"
                    value={
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          handleShowPatient(patient.id);
                        }}
                      >
                        <span>{patient.formatted_name}</span>
                      </Link>
                    }
                    noPadding
                  />
                  <DetailItem
                    xs={12}
                    label="Organization"
                    value={
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          handleShowOrganization(patient.organization.id);
                        }}
                      >
                        <span>{patient.organization.name}</span>
                      </Link>
                    }
                    noPadding
                  />
                  <DetailItem
                    xs={12}
                    label="Created Date"
                    value={UTCDateTimeToLocalDate(patient.created_at)}
                    noPadding
                  />
                  <DetailItem
                    xs={12}
                    label="Modified Date"
                    value={UTCDateTimeToLocalDate(patient.updated_at)}
                    noPadding
                  />
                  {iosFile && (
                    <DetailItem
                      xs={12}
                      label="IOS File"
                      value={
                        iosFile ? (
                          <IOSFileActions patient={patient} iosFile={iosFile} />
                        ) : (
                          'No IOS Files added yet'
                        )
                      }
                      noPadding
                    />
                  )}
                  <DetailItem
                    xs={12}
                    label="Case Status"
                    value={<PatientHoldingAction patient={patient} isLoading={isLoadingPatient} />}
                    noPadding
                  />
                  {!iosFile && stlFiles.length > 0 && (
                    <DetailItem
                      xs={12}
                      label="IOS File"
                      value={
                        <OpenWithExternalApp
                          patient={patient}
                          buttonProps={{
                            variant: 'contained',
                            color: 'primary',
                            size: 'small',
                            title: 'Create IOS File'
                          }}
                          path={`open_patient/${patient.id}`}
                        />
                      }
                      noPadding
                    />
                  )}
                  {get(patient, 'organization.organization_manager') && (
                    <DetailItem
                      xs={12}
                      label="Authorized 3rd Party"
                      value={
                        <Can
                          permissions={['users.view', 'users.manage', 'users.manage-limited']}
                          yes={() => (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <Link
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                handleShowUser(patient.organization.organization_manager.id);
                              }}
                            >
                              <span>{patient.organization.organization_manager.full_name}</span>
                            </Link>
                          )}
                          no={() => (
                            <span>{patient.organization.organization_manager.full_name}</span>
                          )}
                        />
                      }
                      noPadding
                    />
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Box mb={2}>
              <SubSectionWrapper
                title="Doctor Notes"
                subTitle={patient.doctor_notes ? '' : 'N/A'}
                size="normal"
              >
                <PatientNote
                  patientId={patient.id}
                  note={patient.doctor_notes || ''}
                  noteIdentifier="doctor_notes"
                  label="Doctor Notes"
                  rows={6}
                  disabled={isTechnicianOrAdmin(currentUser) || isLabPartner(currentUser)}
                />
              </SubSectionWrapper>
            </Box>
            <Box mb={2}>
              <SubSectionWrapper
                title="Digital Tech Notes"
                subTitle={patient.management_notes ? '' : 'N/A'}
                size="normal"
              >
                <PatientNote
                  patientId={patient.id}
                  note={patient.management_notes || ''}
                  noteIdentifier="management_notes"
                  label="Digital Tech Notes"
                  rows={6}
                  disabled={isDoctor(currentUser) || !canUpdateManagementData}
                />
              </SubSectionWrapper>
            </Box>
            <Box mb={2}>
              <SubSectionWrapper
                title="Physical Tech Notes"
                subTitle={patient.physical_tech_notes ? '' : 'N/A'}
                size="normal"
              >
                <PatientNote
                  patientId={patient.id}
                  note={patient.physical_tech_notes || ''}
                  noteIdentifier="physical_tech_notes"
                  label="Physical Tech Notes"
                  rows={6}
                  disabled={isDoctor(currentUser)}
                />
              </SubSectionWrapper>
            </Box>
            <Can
              permissions={['patients.internal-notes.create']}
              yes={() => (
                <Box mb={2}>
                  <SubSectionWrapper
                    title="Internal Notes"
                    subTitle={patient.internal_notes ? '' : 'N/A'}
                    size="normal"
                  >
                    <PatientNote
                      patientId={patient.id}
                      note={patient.internal_notes || ''}
                      noteIdentifier="internal_notes"
                      label="Internal Notes"
                      rows={6}
                    />
                  </SubSectionWrapper>
                </Box>
              )}
            />
            <Box mb={2}>
              <SubSectionWrapper
                title="On Hold Notes"
                subTitle={
                  Array.isArray(patient.on_hold_notes) && patient.on_hold_notes.length ? '' : 'N/A'
                }
                size="normal"
              >
                <OnHoldNotes
                  patientId={patient.id}
                  notes={patient.on_hold_notes || []}
                  label="On Hold Notes"
                  rows={6}
                  disabled={!patient.is_on_hold}
                />
              </SubSectionWrapper>
            </Box>
          </Grid>
        </Grid>
        {!isEmpty(rxForm) && rxForm.is_request && (
          <InitialSetupWorkflow rxForm={rxForm} iosFile={iosFile} />
        )}
        {patient.customer_request && (
          <RequestsWorkflowSection
            requests={patient.customer_requests}
            iosFileId={iosFile ? iosFile.id : 0}
          />
        )}
      </Container>
    </PatientWorkflowContext.Provider>
  );
};

PatientWorkflow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

PatientWorkflow.defaultProps = {
  match: { params: {} }
};

export default PatientWorkflow;
