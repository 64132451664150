import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from 'antd';
import moment from 'moment';
import Context from './Context';

const ShippingDateFilter = () => {
  const { values, onChange } = useContext(Context);
  const { RangePicker } = DatePicker;

  const props = {
    value: [
      values.shipDateFrom ? moment(values.shipDateFrom) : '',
      values.shipDateTo ? moment(values.shipDateTo) : ''
    ],
    onChange: dates => {
      let shipDateFrom = '';
      let shipDateTo = '';
      if (dates) {
        shipDateFrom = dates[0].startOf('day');
        shipDateTo = dates[1].endOf('day');
      }
      onChange({ shipDateFrom, shipDateTo }, true);
    }
  };

  return (
    <Box pr={1} display="flex" justifyContent="end">
      <Typography component="span" variant="subtitle1">
        Expected Shipping Date&nbsp;
      </Typography>
      <RangePicker {...props} />
    </Box>
  );
};

export default ShippingDateFilter;
