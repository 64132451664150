import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from './Context';
import BaseComponent from '../../../../components/SearchInput';

const Main = ({ value, inputRef, ...props }) => {
  return <BaseComponent inputRef={inputRef} value={value} {...props} />;
};

Main.propTypes = {
  value: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

const Wrapper = ({ name, checkDisable, ...props }) => {
  const { searchRefs, values, search } = useContext(Context);
  const value = values[name];
  const ref = searchRefs[name];

  const overrideProps = {};
  if (checkDisable) {
    overrideProps.disabled = checkDisable(values);
  }

  return (
    <Main
      value={value}
      inputRef={ref}
      name={name}
      ignore
      onChange={e => {
        search(name, e.target.value);
      }}
      {...props}
      {...overrideProps}
    />
  );
};

Wrapper.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ignore: PropTypes.array,
  checkDisable: PropTypes.func
};

Wrapper.defaultProps = {
  ignore: [','],
  checkDisable: null
};

export default Wrapper;
