import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { debounce } from 'lodash';
import { connect, useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import defaultLayout, {
  SEARCH_BY_ORGANIZATION_OR_DOCTOR,
  SEARCH_BY_ORGANIZATION_TYPE,
  SEARCH_BY_PATIENT, SEARCH_BY_TRACKING_NUMBER
} from './layouts/default';
import Context from './Context';
import SearchInput from './SearchInput';
import KeywordsOnly from './KeywordsOnly';
import RequestFilters from './RequestFilters';
import ShippingDateFilter from './ShippingDateFilter';
import ToggleFilter from './ToggleFilter';
import Sorting from './Sorting';
import ExpandCollapseIconButton from '../../../../components/IconButtons/ExpandCollapseIconButton';
import { LAYOUT_BATCH_MODE, LAYOUT_KEYWORDS_ONLY } from '../../layout';
import keywordsOnly from './layouts/keywordsOnly';
import batchMode from './layouts/batchMode';
import BatchMode from './BatchMode';
import hasPermission from '../../../../selectors/hasPermission';

const COMMON_TYPES = {
  items: PropTypes.arrayOf(
    PropTypes.arrayOf({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

const useStyles = makeStyles(theme => ({
  misc: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const Filter = ({ type, ...props }) => {
  const context = useContext(Context);
  const isAllowed = useSelector(
    state => !props.permissions || hasPermission(state, {permissions: props.permissions})
  );
  if (!isAllowed) {
    return '';
  }

  if (type === 'group' || type === 'group_inline') {
    return <Group inline={type === 'group_inline'} {...props} />;
  }

  if (!props.label && props.name) {
    const { name } = props;
    props.label = name.charAt(0).toUpperCase() + name.slice(1);
  }

  switch (type) {
    case 'search': {
      return <SearchInput {...props} />;
    }
    case 'toggle': {
      return <ToggleFilter {...props} />;
    }
    case 'keyword_only': {
      return <KeywordsOnly layout={context.layout} setLayout={context.setLayout} />;
    }
    case 'request_filters': {
      return <RequestFilters />;
    }
    case 'shipping_date': {
      return <ShippingDateFilter />;
    }
    case 'lazy': {
      // eslint-disable-next-line react/prop-types
      const { callback } = props;
      const itemProps = callback({ ...props, ...context });
      return <Filter {...itemProps} />;
    }
    case 'sorting': {
      return <Sorting {...props} />;
    }
    case 'batch_mode': {
      return <BatchMode {...props} />;
    }
    default:
      return '';
  }
};

const Group = ({ inline, items, ContainerComponent, containerProps }) => {
  if (!inline) {
    return (
      <Grid item xs={12}>
        <Grid container {...containerProps}>
          {items.map(({ gridItem, ...itemProps }) => {
            return (
              <Grid item {...gridItem}>
                <Filter {...itemProps} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }

  const children = (
    <>
      {items.map(item => (
        <Filter {...item} />
      ))}
    </>
  );
  if (ContainerComponent) {
    return <ContainerComponent {...containerProps}>{children}</ContainerComponent>;
  }
  return children;
};

Group.propTypes = {
  inline: PropTypes.bool,
  items: COMMON_TYPES.items,
  containerProps: PropTypes.object
};

Group.defaultProps = {
  inline: false,
  containerProps: {}
};

const Filters = ({
  values,
  additionalContext,
  onChange,
  onSort,
  layout,
  setLayout,
  collapsedAll,
  setCollapsedAll,
  autoRefresh,
  setAutoRefresh,
  refresh,
  batchedCases,
  setBatchedCases,
  onReset
}) => {
  const classes = useStyles();
  let filtersLayout;

  switch (layout) {
    case LAYOUT_KEYWORDS_ONLY:
      filtersLayout = keywordsOnly;
      break;
    case LAYOUT_BATCH_MODE:
      filtersLayout = batchMode;
      break;
    default:
      filtersLayout = defaultLayout;
  }

  const searchRefs = {
    [SEARCH_BY_PATIENT.name]: useRef(null),
    [SEARCH_BY_ORGANIZATION_OR_DOCTOR.name]: useRef(null),
    [SEARCH_BY_ORGANIZATION_TYPE.name]: useRef(null),
    [SEARCH_BY_TRACKING_NUMBER.name]: useRef(null)
  };

  const search = debounce((name, value) => {
    onChange(
      {
        [name]: value
      },
      true
    );
  }, 250);

  const handleReset = () => {
    Object.keys(searchRefs).forEach(key => {
      if (searchRefs[key].current) {
        searchRefs[key].current.value = '';
      }
    });
    onReset();
  }

  return (
    <Context.Provider
      value={{
        searchRefs,
        values,
        search,
        layout,
        refresh,
        setLayout,
        collapsedAll,
        setCollapsedAll,
        onChange,
        onSort,
        batchedCases,
        setBatchedCases,
        ...additionalContext
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end">
            <Group
              group="main"
              items={filtersLayout}
              containerProps={{ spacing: 2, alignItems: 'flex-end' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.misc}>
          <ExpandCollapseIconButton
            expandTitle="Expand All"
            collapseTitle="Collapse All"
            onClick={isCollapse => {
              setCollapsedAll(isCollapse);
            }}
            collapsed={collapsedAll}
          />
          <FormControlLabel
            checked={autoRefresh}
            onChange={(event) => setAutoRefresh(event.target.checked)}
            control={<Switch name="auto-refresh" color="primary" />}
            label="Auto-Refresh"
          />
          <Button color="primary" title="Refresh" onClick={refresh} variant="contained">
            Refresh
          </Button>
          <Button color="secondary" title="Reset Filters" onClick={handleReset} variant="contained">
            Reset Filters
          </Button>
        </Grid>
      </Grid>
    </Context.Provider>
  );
};

Filters.propTypes = {
  context: PropTypes.shape({
    userRole: PropTypes.string
  })
};

Filters.defaultProps = {
  context: {}
};

export default connect((state, ownProps) => {
  return {
    additionalContext: {
      userRole: state.auth.user.roles[0]?.name
    }
  };
})(Filters);
