import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import TechWorkflow from './TechWorkflow';
import TechWorkflowApiService from '../../services/api/techWorkflow';
import { formatProcesses, formatProcessRequest } from '../../helpers/techWorkflow';
import LoadingIndicator from '../LoadingIndicator';
import TechWorkflowProcessApiService from '../../services/api/techWorkflowProcess';
import { enqueueNotification } from '../../reducers/notifications';

const Manager = ({ id, afterUpdate }) => {
  const [data, setData] = useState(null);
  const apiService = new TechWorkflowProcessApiService();
  const dispatch = useDispatch();
  const retrieve = useCallback(() => {
    const api = new TechWorkflowApiService();
    return api.retrieve(id).then(({ data }) => {
      const formatedProcesses = formatProcesses(data.processes);
      setData({
        id: data.id,
        processes: formatedProcesses
      });
    });
  }, [id]);

  const handleSave = (updated, old) => {
    return new Promise((resolve, reject) => {
      apiService
        .update(old.id, formatProcessRequest(updated))
        .then(response => {
          retrieve().then(() => {
            dispatch(enqueueNotification('success', response.message));
            resolve(response);
          });
          if (afterUpdate) {
            afterUpdate();
          }
        })
        .catch(error => {
          dispatch(enqueueNotification('error', error.message));
          reject(error);
        });
    });
  };

  const handleStart = record => {
    return apiService
      .start(record.id)
      .then(response => {
        retrieve().then(() => {
          dispatch(enqueueNotification('success', response.message));
        });
        if (afterUpdate) {
          afterUpdate();
        }
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error));
      });
  };

  const handleFinish = record => {
    return apiService
      .finish(record.id)
      .then(response => {
        retrieve().then(() => {
          dispatch(enqueueNotification('success', response.message));
        });
        if (afterUpdate) {
          afterUpdate();
        }
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error));
      });
  };

  useEffect(() => {
    if (!data || data.id !== id) {
      retrieve();
    }
  }, [data, setData, id, retrieve]);

  if (!data) {
    return <LoadingIndicator />;
  }

  return (
    <TechWorkflow
      workflowData={data.processes}
      save={handleSave}
      start={handleStart}
      finish={handleFinish}
    />
  );
};

Manager.propTypes = {
  id: PropTypes.number.isRequired
};

export default Manager;
