import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { each } from 'lodash';
import { useHistory } from 'react-router-dom';
import DataTable from './DataTable';
import { fetchPatientsAction, initialState, setInitialParameters } from '../../reducers/patients';
import { findRolesInUser } from '../../helpers';
import { ROLES } from '../../constants';
import extractUrlQueryParameters from './helpers/extractUrlQueryParameters';
import { COMMON_PATIENT_INCLUDES } from '../../constants/queries';
import { refineParameters } from './helpers/parametersRefinement';

const PatientsPage = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const globalParams = useSelector(state => state.patients.globalParams);
  const customGlobalParamsInitialized = useSelector(
    state => state.patients.customGlobalParams !== null
  );
  const layout = useSelector(state => state.patients.layout);
  const is3PP = useSelector(state => findRolesInUser([ROLES.labPartner], state.auth.user));
  const selectedOrganizations = useSelector(state => state.auth.management.selectedOrganizations);
  const urlQueryParams = new URLSearchParams(location.search);
  const paramsFromUrl = extractUrlQueryParameters(location.search);

  const initialParamsFor3PP = {
    requestAssignments: []
  };
  const [additionalParams, setAdditionalParams] = useState({
    params: {
      ...globalParams,
      ...paramsFromUrl
    },
    resetPage: false
  });

  const handleUpdateData = useCallback(
    parameters => {
      if (!customGlobalParamsInitialized) {
        return;
      }
      parameters.includes = [...COMMON_PATIENT_INCLUDES];
      refineParameters(layout, parameters, { is3PP });

      dispatch(
        fetchPatientsAction({
          ...parameters,
          organizations: selectedOrganizations.map(org => org.id)
        })
      );
    },
    [dispatch, selectedOrganizations, is3PP, customGlobalParamsInitialized, layout]
  );

  const removeQueryParam = useCallback(
    (...paramsToRemove) => {
      let paramsRemoved = false;

      each(paramsToRemove, param => {
        if (urlQueryParams.has(param)) {
          urlQueryParams.delete(param);
          paramsRemoved = true;
        }
      });

      if (paramsRemoved) {
        history.replace({
          search: urlQueryParams.toString()
        });
      }
    },
    [urlQueryParams, history]
  );

  useEffect(() => {
    removeQueryParam(...Array.from(urlQueryParams.keys()));
  }, [urlQueryParams, removeQueryParam]);

  // initialize custom parameters
  useEffect(() => {
    if (customGlobalParamsInitialized) {
      return;
    }
    const initialParams = is3PP ? initialParamsFor3PP : {};
    dispatch(setInitialParameters(initialParams));
    setAdditionalParams({
      params: {
        ...globalParams,
        ...initialParams,
        ...paramsFromUrl
      },
      resetPage: false
    });
  }, [
    is3PP,
    globalParams,
    paramsFromUrl,
    initialParamsFor3PP,
    dispatch,
    customGlobalParamsInitialized
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedParams = extractUrlQueryParameters(queryParams);
    if (Object.keys(updatedParams).length) {
      setAdditionalParams({
        params: {
          ...initialState.globalParams,
          ...initialState.customGlobalParams,
          ...updatedParams
        },
        resetPage: true
      });
    }
  }, [urlQueryParams, location]);

  return (
    <DataTable
      {...{
        handleUpdateData,
        setAdditionalParams,
        additionalParams,
        urlQueryParams,
        globalParams
      }}
    />
  );
};

PatientsPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

PatientsPage.defaultProps = {};
export default PatientsPage;
