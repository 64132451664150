import React, { useCallback, useEffect } from 'react';
import { connect } from 'formik';
import { decodeFormikValues, encodeFormikValues } from '../../helpers';

/**
 * Hides field and set to value to null if dependency criteria doesn't meet
 *
 * @param Field
 * @returns {React.ComponentType<unknown>}
 */
export default Field => {
  return connect(
    ({
      formik: { values: formikValues, setFieldValue },
      name,
      dependency,
      resetValue = null,
      preferenceValue,
      ...props
    }) => {
      const values = decodeFormikValues(formikValues);
      const currentValue = values[name];
      const setValue = useCallback(
        value => {
          const encode = encodeFormikValues({ [name]: value });
          setFieldValue(name, encode[name]);
        },
        [name, setFieldValue]
      );

      useEffect(() => {
        const hasValue = Array.isArray(currentValue) ? currentValue.length > 0 : !!currentValue;

        if (!dependency && hasValue) {
          if (resetValue) {
            resetValue(setFieldValue);
          } else {
            setValue(null);
          }
          return;
        }

        if (dependency && currentValue === null) {
          setValue(preferenceValue);
        }
      }, [name, dependency, preferenceValue, resetValue, currentValue, setValue, setFieldValue]);

      if (
        dependency !== undefined &&
        (dependency === false || currentValue === null || currentValue === undefined)
      ) {
        return '';
      }

      return (
        <Field
          name={name}
          currentValue={currentValue}
          setValue={setValue}
          preferenceValue={preferenceValue}
          {...props}
        />
      );
    }
  );
};
