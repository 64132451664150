import Box from '@material-ui/core/Box';
import {
  SEARCH_BY_ORGANIZATION_OR_DOCTOR,
  SEARCH_BY_ORGANIZATION_TYPE,
  SEARCH_BY_PATIENT
} from './default';

export default [
  {
    gridItem: { xs: 12, md: 4 },
    ...SEARCH_BY_PATIENT
  },
  {
    ...SEARCH_BY_ORGANIZATION_OR_DOCTOR,
    gridItem: { xs: 12, md: 4 }
  },
  {
    ...SEARCH_BY_ORGANIZATION_TYPE,
    gridItem: { xs: 12, md: 4 }
  },
  {
    type: 'group_inline',
    name: 'batch_group',
    gridItem: { xs: 12 },
    ContainerComponent: Box,
    containerProps: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    items: [
      {
        type: 'toggle',
        name: 'rushCaseOnly',
        label: 'Rush Case Only'
      },
      {
        type: 'shipping_date',
        name: 'shipping_date'
      },
      {
        type: 'sorting'
      }
    ]
  },
  {
    type: 'group_inline',
    name: 'group_b',
    gridItem: { xs: 12 },
    ContainerComponent: Box,
    containerProps: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    items: [
      {
        type: 'batch_mode',
        name: 'batch'
      }
    ]
  },
  {
    type: 'group_inline',
    name: 'group_c',
    items: [
      {
        type: 'item',
        name: 'collapse'
      },
      {
        type: 'item',
        name: 'auto-refresh'
      },
      {
        type: 'item',
        name: 'onhold'
      },
      {
        type: 'item',
        name: 'reset_filters'
      }
    ]
  }
];
