import { blueGrey, green } from '@material-ui/core/colors';

export default theme => ({
  date: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      display: 'inline-block',
      fontSize: 12
    }
  },
  dibsButtonBadge: {
    marginRight: theme.spacing(1),
    '& .MuiBadge-badge': {
      height: 'auto',
      fontSize: 9,
      padding: 3,
      color: 'white'
    }
  },
  dibsButtonBadgeStl: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.success.main
    }
  },
  dibsButtonBadgeIos: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.primary.main
    }
  },
  compact: {
    maxWidth: 1400,
    '& .MuiTableCell-root.MuiTableCell-body': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    }
  },
  collapsedRow: {
    '& > td.MuiTableCell-root.MuiTableCell-body': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      verticalAlign: 'top'
    }
  },
  compactButtons: {
    margin: theme.spacing(0.5)
  },
  dibsButton: {},
  workflowGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  statusTag: {
    width: '100%',
    marginLeft: 0
  },
  workflowItem: {
    minHeight: 24
  },
  switch: {
    paddingTop: 0,
    paddingRight: 0
  },
  time: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    display: 'block',
    marginBottom: '0.5rem',
    marginLeft: '0.85rem'
  },
  patientDetailsLink: {
    display: 'flex',
    flexDirection: 'column'
  },
  organizationDetailsLink: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    color: 'gray'
  },
  assignedTo: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    backgroundColor: blueGrey[600]
  },
  requestItem: {
    display: 'flex'
  },
  buttonFilter: {
    marginLeft: theme.spacing(1)
  },

  refreshButton: {},
  resetButton: {
    display: 'inline-flex'
  },
  greenButton: {
    color: green[500]
  }
});
