import React, { useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Content from '../../components/Layout/Content';
import DataTable from '../../components/DataTable';
import SearchInput from '../../components/SearchInput';
import { fetchBatchesAction } from '../../reducers/batches';
import { STATUSES } from './constants';

const Filters = ({ values, onChange }) => {
  const handleSearchByName = event => {
    onChange({ ...values, searchByName: event.target.value });
  };

  const handleStatusChange = (event, value) => {
    onChange({ ...values, status: value });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <SearchInput
          value={values.searchByName}
          placeholder="Batch Name"
          onChange={handleSearchByName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ToggleButtonGroup
          exclusive
          value={values.status}
          onChange={handleStatusChange}
          aria-label="text formatting"
        >
          <ToggleButton value="new" aria-label="new">
            New
          </ToggleButton>
          <ToggleButton value="in_progress" aria-label="in-progress">
            In Progress
          </ToggleButton>
          <ToggleButton value="completed" aria-label="completed">
            Completed
          </ToggleButton>
          <ToggleButton value="all" aria-label="all">
            All
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

const Batches = ({ fetch, fetching, data, total, parameters }) => {
  const history = useHistory();
  const columns = [
    {
      id: 'id',
      label: 'Batch ID'
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'status',
      label: 'Status',
      formatMethod: value => {
        return STATUSES[value].label;
      },
      disabledSorting: true
    },
    {
      id: 'number_of_cases',
      label: '# of Cases',
      disabledSorting: true
    },
    {
      id: 'created_at',
      label: 'Creation',
      type: 'datetime',
      datetime_format: 'ddd hh:mma DD-MMM-YYYY'
    }
  ];

  const actions = [
    {
      name: 'View',
      handleOnAction: id => history.push(`/batches/${id}`),
      text: 'View',
      title: 'View'
    }
  ];

  const handleUpdateData = useCallback(
    criteria => {
      fetch(criteria);
    },
    [fetch]
  );

  const globalParams = useMemo(
    () => ({
      orderBy: parameters.orderBy,
      order: parameters.order,
      page: parameters.page,
      perPage: parameters.perPage
    }),
    [parameters.orderBy, parameters.order, parameters.page, parameters.perPage]
  );

  const additionalParams = useMemo(
    () => ({
      ...globalParams,
      params: {
        status: parameters.status,
        searchByName: parameters.searchByName
      }
    }),
    [globalParams, parameters.status, parameters.searchByName]
  );

  const handleFiltersChange = newFilterValues => {
    fetch({ ...parameters, ...newFilterValues });
  };

  const renderFilters = () => (
    <Filters
      values={{
        status: parameters.status,
        searchByName: parameters.searchByName
      }}
      onChange={handleFiltersChange}
    />
  );

  return (
    <Content filters={renderFilters()}>
      <DataTable
        isLoading={fetching}
        rows={data}
        columns={columns}
        updateData={handleUpdateData}
        total={total}
        defaultOrderBy={parameters.orderBy}
        globalParams={globalParams}
        additionalParams={additionalParams}
        customActions={actions}
      />
    </Content>
  );
};

Batches.propTypes = {
  fetch: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  data: PropTypes.object,
  total: PropTypes.number.isRequired,
  parameters: PropTypes.object
};

export default connect(
  (state, props) => {
    const batchesState = state.batches;
    return {
      initialized: batchesState.listing.initialized,
      data: batchesState.listing.data,
      total: batchesState.listing.total,
      fetching: batchesState.listing.fetching,
      parameters: batchesState.listing.parameters
    };
  },
  (dispatch, props) => {
    return {
      fetch: parameters => dispatch(fetchBatchesAction(parameters))
    };
  }
)(Batches);
