import { Query } from 'cogent-js';
import BaseApiService from './base';
import { encodeURIComponentArray, serializeDate } from '../../helpers';

export default class PatientApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
    this.resourcePath = '/patients';
  }

  get({
    perPage = 5,
    page = 1,
    order,
    orderBy,
    userId = '',
    organizationId = '',
    searchFullNameQuery = '',
    includes = [],
    searchDoctorFullNameOrOrganizationQuery = '',
    patientStatusId = '',
    onlyDeleted = false,
    onlyOnhold = 'without',
    withOnhold = false,
    requestTypeStatus = [],
    requestStep = null,
    requestSteps = [],
    organizations = [],
    shippingTypeFilter = [],
    shipDateFrom,
    shipDateTo,
    customerServiceManager = '',
    requestAssignments = [],
    activeRequestOnly = true,
    rushCaseOnly = false,
    doctorNotesOnly = false,
    shipping = null,
    createdDateFrom,
    createdDateTo,
    completedCaseDateFrom,
    completedCaseDateTo,
    cancelled = null,
    stats = null,
    shippedDateFrom,
    shippedDateTo,
    ids = [],
    trackingNumber = null,
    archived = 'without',
    withoutBatch = false,
    readyForPrint = false
  }) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'id';
    const from = serializeDate(shipDateFrom);
    const to = serializeDate(shipDateTo);

    const createdFrom = serializeDate(createdDateFrom);
    const createdTo = serializeDate(createdDateTo);

    const completedCaseFrom = serializeDate(completedCaseDateFrom);
    const completedCaseTo = serializeDate(completedCaseDateTo);

    const shippedFrom = serializeDate(shippedDateFrom);
    const shippedTo = serializeDate(shippedDateTo);

    const withOnHoldStr = (withOnhold && (activeRequestOnly ? 'with' : 'only')) || 'without';
    const held = onlyOnhold === 'without' ? withOnHoldStr : onlyOnhold;

    let url = this.query
      .for('patients')
      .include(...includes)
      .where('user_id', userId)
      .where('organization_id', organizationId)
      .where('patient_status_id', patientStatusId)
      .where('patient_full_name_like', searchFullNameQuery)
      .where('doctor_full_name_or_organization_like', searchDoctorFullNameOrOrganizationQuery)
      .where('trashed', onlyDeleted ? 'only' : '')
      .where('held', held)
      .where('cancelled', cancelled)
      .where('stats', stats)
      .where('active_request_only', activeRequestOnly)
      .where('archived', archived)
      .where('rush_case_only', rushCaseOnly)
      .where('doctor_notes_only', doctorNotesOnly)
      .where('shipping', shipping)
      .where('organization_type', customerServiceManager)
      .whereIn('ids', encodeURIComponentArray(ids))
      .whereIn('filter_assignments', encodeURIComponentArray(requestAssignments))
      .whereIn('chosen_wi_organization_id', organizations)
      .whereIn('filter_request_type_status', encodeURIComponentArray(requestTypeStatus))
      .where('request_step', requestStep)
      .whereIn('request_steps', requestSteps)
      .where('tracking_number', trackingNumber)
      .whereIn('filter_shipping_type', shippingTypeFilter)
      .where('filter_ship_date_range', from && to ? [from, to].join(',') : null)
      .where('without_batch', withoutBatch)
      .where(
        'created_at_between',
        createdFrom && createdTo ? [createdFrom, createdTo].join(',') : null
      )
      .where(
        'completed_case_between',
        completedCaseFrom && completedCaseTo ? [completedCaseFrom, completedCaseTo].join(',') : null
      )
      .where(
        'shipped_between',
        shippedFrom && shippedTo ? [shippedFrom, shippedTo].join(',') : null
      )
      .where('ready_for_print', readyForPrint)
      .sort(orderChar + orderByField)
      .params({
        perPage,
        page
      });

    url = url.get();

    return this.request({
      method: 'get',
      url
    });
  }

  getSpecific(ids, includes) {
    let url = this.query
      .whereIn('ids', encodeURIComponentArray(ids))
      .for('patients')
      .include(...includes);

    url = url.get();

    return this.request({
      method: 'get',
      url
    });
  }

  show(id, includes = []) {
    let url = this.query.for(`patients/${id}`).include(includes);

    url = url.get();

    return this.request({
      method: 'get',
      url
    });
  }

  create(data) {
    return this.request({
      method: 'post',
      url: this.resourcePath,
      data: {
        ...data,
        auto_increment: true
      }
    });
  }

  update(id, data) {
    return this.request({
      method: 'put',
      url: `${this.resourcePath}/${id}`,
      data
    });
  }

  delete(id, data) {
    return this.request({
      method: 'delete',
      url: `${this.resourcePath}/${id}`,
      data
    });
  }

  restore(id) {
    return this.request({
      method: 'post',
      url: `${this.resourcePath}/${id}/restore`
    });
  }

  complete(id) {
    return this.request({
      method: 'post',
      url: `${this.resourcePath}/${id}/complete_setup`
    });
  }

  reverseCase(id) {
    return this.request({
      method: 'post',
      url: `${this.resourcePath}/${id}/case-reversal`
    });
  }

  checkCaseReversalCompliance(id) {
    return this.request({
      method: 'get',
      url: `${this.resourcePath}/${id}/case-reversal/check`
    });
  }

  hold(id, data = null) {
    let request = {
      method: 'put',
      url: `/patients/${id}/toggle_hold`
    };
    if (data) {
      request = { ...request, data };
    }
    return this.request(request);
  }

  getFilesDownloadUrls(patientId, fileTypeId) {
    return this.request({
      method: 'get',
      url: `${this.resourcePath}/${patientId}/images`,
      params: {
        thumbnails: true,
        patient_file_type_id: fileTypeId
      }
    });
  }

  getImageUrls(patientId, ids) {
    return this.request({
      method: 'get',
      url: `${this.resourcePath}/${patientId}/images`,
      params: {
        ids
      }
    });
  }

  archive(id, data) {
    return this.request({
      method: 'put',
      url: `${this.resourcePath}/${id}/archive`,
      data
    });
  }

  restoreBackupIos(id) {
    return this.request({
      method: 'post',
      url: `${this.resourcePath}/${id}/restore-backup-ios`
    });
  }

  checkSimilarPatient(data) {
    return this.request({
      method: 'get',
      url: `${this.resourcePath}/check_similar_patient`,
      params: { ...data }
    });
  }
}
