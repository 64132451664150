import { combineReducers } from 'redux';
import auth from './auth';
import notifications from './notifications';
import loading from './loading';
import error from './error';
import printingFiles from './printingFiles';
import users from './users';
import licenses from './licenses';
import roles from './roles';
import patients from './patients';
import questions from './questions';
import products from './products';
import contracts from './contracts';
import settings from './settings';
import printingRequests from './printingRequests';
import orders from './orders';
import latestPatients from './latestPatients';
import promotionCodes from './promotionCodes';
import periodicRule from './periodicRule';
import locations from './locations';
import rxForms from './rxForms';
import installers from './installers';
import requests from './requests';
import dashboard from './dashboard';
import organizations from './organizations';
import onboardings from './onboardings';
import globalSearch from './globalSearch';
import pricing from './pricing';
import bracketLibraries from './bracketLibraries';
import organizationUsers from './organizationUsers';
import rxFormPreferences from './rxFormPreferences';
import modals from './modals';
import organizationPricing from './organizationPricing';
import announcement from './annoucement';
import archWires from './archWires';
import whitehatUserIntegrations from './whitehatUserIntegrations';
import formBuilder from './formBuilder';
import userNotificationSettings from './userNotificationSettings';
import caseFeedback from './caseFeedback';
import globalBackdrop from './globalBackdrop';
import batches from './batches';

export default combineReducers({
  auth,
  dashboard,
  settings,
  notifications,
  printingFiles,
  users,
  roles,
  questions,
  licenses,
  patients,
  printingRequests,
  orders,
  latestPatients,
  products,
  loading,
  contracts,
  promotionCodes,
  periodicRule,
  error,
  locations,
  organizations,
  rxForms,
  installers,
  requests,
  onboardings,
  globalSearch,
  pricing,
  bracketLibraries,
  organizationUsers,
  rxFormPreferences,
  modals,
  organizationPricing,
  announcement,
  archWires,
  whitehatUserIntegrations,
  formBuilder,
  userNotificationSettings,
  caseFeedback,
  globalBackdrop,
  batches
});
