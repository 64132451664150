import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class TechWorkflowApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  retrieve(id) {
    return this.request({
      method: 'get',
      url: `/tech-workflows/${id}`
    });
  }

  updateProcesses(techWorkflowId, processes) {
    return this.request({
      method: 'put',
      url: `/tech-workflows/${techWorkflowId}/processes`,
      data: { processes }
    });
  }
}
