import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import WorkIcon from '@material-ui/icons/WorkSharp';
import WorkOutlineIcon from '@material-ui/icons/WorkOutlineSharp';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { removeCaseAction } from '../../../reducers/batches';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

const useStyles = makeStyles(() => ({
  greenButton: {
    color: green[500]
  }
}));

const Patients = ({ batch, remove }) => {
  const classes = useStyles();
  const [patientToRemove, setPatientToRemove] = useState(null);
  const history = useHistory();

  const createDeleteHandler = patient => () => {
    setPatientToRemove(patient);
  };

  const confirmationDialogHandler = response => {
    if (response) {
      remove(patientToRemove.id);
    }
    setPatientToRemove(null);
  };

  useEffect(() => {
    if (batch.cases.length === 0) {
      history.push('/batches');
    }
  }, [batch, history]);

  return (
    <TableContainer component={Card} style={{ width: '80%', marginTop: 16 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Patient Name</TableCell>
            <TableCell>Print Request</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {batch.cases.map(patient => {
            const { id, name, print_workflow } = patient;
            const isFinished = print_workflow.next_step.process === 'Finished';
            const requestLabel = !isFinished
              ? `[${get(print_workflow, 'next_step.id')}] ${get(
                  print_workflow,
                  'next_step.process'
                )}`
              : 'Finished';
            const isClaimed = Boolean(
              print_workflow.next_step.tech_name ||
                print_workflow.next_step.time_start ||
                print_workflow.next_step.time_end
            );
            const workflowButtonClasses = isClaimed ? [classes.greenButton] : [];

            return (
              <TableRow key={id}>
                <TableCell component="th" scope="row">
                  {name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {requestLabel}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    className={workflowButtonClasses.join(' ')}
                    edge="end"
                    aria-label="view"
                    onClick={() => window.open(`/patient-workflow/${id}`)}
                  >
                    {isClaimed ? <WorkIcon /> : <WorkOutlineIcon />}
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="secondary"
                    onClick={createDeleteHandler(patient)}
                    disabled={batch.status === 'completed'}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {patientToRemove !== null && (
        <ConfirmationDialog
          open={patientToRemove}
          handleClose={confirmationDialogHandler}
          title="Remove case from batch"
          confirmText="Yes"
          bodyText={`Are you sure you want to remove "${patientToRemove.name}" from the batch?${
            batch.cases.length === 1
              ? ' (Warning: Removing this case will also remove the batch)'
              : ''
          }`}
        />
      )}
    </TableContainer>
  );
};

Patients.propTypes = {
  batch: PropTypes.object.isRequired
};

export default connect(
  () => ({}),
  (dispatch, props) => {
    return {
      remove: id => {
        dispatch(removeCaseAction(props.batch.id, id));
      }
    };
  }
)(Patients);
