import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Divider } from '@material-ui/core';
import Content from '../../../components/Layout/Content';
import Form from './Form';
import LoadingIndicator from '../../../components/LoadingIndicator';
import NotFoundPage from '../../NotFoundPage';
import { fetchBatchAction } from '../../../reducers/batches';
import Patients from './Patients';
import Workflow from './Workflow';

const BatchPage = ({ batch, fetch, fetching }) => {
  const [initialized, setInitialized] = useState(Boolean(batch));

  useEffect(() => {
    if (!batch && !fetching) {
      fetch().then(() => {
        setInitialized(true);
      });
    }
  }, [batch, fetching, fetch]);

  if (fetching || !initialized) {
    return <LoadingIndicator />;
  }

  if (!batch) {
    return <NotFoundPage />;
  }

  return (
    <Content>
      <Form batch={batch} />
      <Divider />
      <Patients batch={batch} />
      <Divider />
      <Workflow batch={batch} refreshOnChange={fetch} />
    </Content>
  );
};

BatchPage.propTypes = {};

export default withRouter(
  connect(
    (state, props) => {
      const batch = state.batches.listing.data.find(
        item => item.id === Number(props.match.params.id)
      );
      return {
        batch,
        fetching: state.batches.show.fetching
      };
    },
    (dispatch, props) => {
      return {
        fetch: () => dispatch(fetchBatchAction(Number(props.match.params.id)))
      };
    }
  )(BatchPage)
);
