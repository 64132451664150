import { Box } from '@material-ui/core';
import React from 'react';
import withOnboarding from './withOnboarding';
import OnboardingAlert from '../pages/RxForms/components/OnboardingAlert';

export default Component => {
  return withOnboarding(({ onboarding, ...props }) => {
    if (onboarding.inProgress && onboarding.currentStep < 8) {
      return (
        <Box mb={5}>
          <OnboardingAlert />
        </Box>
      );
    }
    return <Component {...props} />;
  });
};
