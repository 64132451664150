import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import Manager from '../../../components/Workflow/Manager';

const Workflow = ({ batch, refreshOnChange }) => {
  return (
    <Card style={{ width: '80%' }}>
      <CardHeader title="Workflow" />
      <Divider />
      <CardContent>
        <Manager id={batch.tech_workflow_id} afterUpdate={refreshOnChange} />
      </CardContent>
      <Divider />
    </Card>
  );
};

Workflow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  batch: PropTypes.object.isRequired
};

export default Workflow;
