import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, lightGreen } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { checkNeededStep } from '../../../../helpers/batching';

const useStyles = makeStyles(theme => ({
  tableBody: {
    borderBottom: `1px double ${theme.palette.grey[500]}`,
    '&.batch-ready': {
      backgroundColor: lightGreen[100]
    }
  },
  cell: {
    paddingLeft: 77
  },
  orgName: {
    fontSize: 14
  },
  address: {
    fontSize: 12,
    fontStyle: 'italic'
  },
  rush: {
    backgroundColor: `${deepOrange[100]} !important`
  }
}));

const isGroupReadyForBatching = rows => {
  return rows.length === rows.filter(checkNeededStep).length;
};

const BatchView = ({ rows, keyValue, organization, location, rowRenderer, hasRushCase }) => {
  const classes = useStyles();

  const bodyClassNames = [classes.tableBody];

  if (isGroupReadyForBatching(rows)) {
    bodyClassNames.push('batch-ready');
  }

  return (
    <TableBody key={keyValue} className={bodyClassNames.join(' ')}>
      {rows.map((item, index) => {
        const rowProps = {};

        if (item.rx_form && item.rx_form.rush_case) {
          rowProps.className = classes.rush;
        }
        return rowRenderer(item, index, rowProps);
      })}
      <TableRow key={`batch-view-${keyValue}`}>
        <TableCell colSpan={4} className={classes.cell}>
          <Typography variant="subtitle1" className={classes.orgName}>
            {organization.name}{' '}
          </Typography>
          <Typography variant="caption" className={classes.address}>
            {location ? location.full_address : ''}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

BatchView.propTypes = {};

export default BatchView;
