import React from 'react';
import { TextField } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useInputStyles = makeStyles(() => ({
  root: {
    borderRadius: 0
  },
  input: {
    textAlign: 'center',
    padding: '8px'
  }
}));

const IntegerInput = ({
  name,
  TextFieldProps: { helperText = null, ...otherTextFieldProps },
  ...props
}) => {
  const inputClasses = useInputStyles();

  return (
    <Field name={name} {...props}>
      {({ field, meta }) => {
        let helper = null;

        if (meta.touched && meta.error) {
          helper = meta.error;
        } else if (helperText) {
          helper = helperText;
        }

        return (
          <TextField
            variant="outlined"
            autoComplete="off"
            fullWidth
            {...field}
            error={meta.touched && Boolean(meta.error)}
            helperText={helper}
            InputProps={{ classes: inputClasses }}
            {...otherTextFieldProps}
          />
        );
      }}
    </Field>
  );
};

IntegerInput.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  TextFieldProps: PropTypes.any
};

IntegerInput.defaultProps = {
  TextFieldProps: {}
};

export default IntegerInput;
