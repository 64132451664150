import React from 'react';
import PropTypes from 'prop-types';
import RadioGroupField from './RadioGroupField';
import withDependency from './hooks/withDependency';
import { ROOT_INTEGRATION_TYPE_LABELS, ROOT_INTEGRATION_TYPES } from '../constants';
import { formatCurrency } from '../../../helpers';

const RootIntegrationField = ({ fees, ...props }) => {
  const options = [
    {
      label: `${ROOT_INTEGRATION_TYPE_LABELS[ROOT_INTEGRATION_TYPES.DICOM]} | ${formatCurrency(
        fees.root_integration
      )}`,
      value: ROOT_INTEGRATION_TYPES.DICOM
    }
  ];

  if ((process.env.REACT_APP_ROOT_INTEGRATION_WITH_AI_OPTION_DISABLED || 'false') !== 'true') {
    options.push({
      label: `${ROOT_INTEGRATION_TYPE_LABELS[ROOT_INTEGRATION_TYPES.AI]} | ${formatCurrency(
        fees.root_integration_with_ai || 0
      )}`,
      value: ROOT_INTEGRATION_TYPES.AI
    });
  }

  options.push({
    label: ROOT_INTEGRATION_TYPE_LABELS[ROOT_INTEGRATION_TYPES.NONE],
    value: ROOT_INTEGRATION_TYPES.NONE
  });

  return <RadioGroupField options={options} {...props} fieldProps={{ row: false }} />;
};

RootIntegrationField.propTypes = {
  fees: PropTypes.shape({
    root_integration: PropTypes.number.isRequired,
    root_integration_with_ai: PropTypes.number.isRequired
  })
};

RootIntegrationField.defaultProps = {
  fees: {
    root_integration: 0,
    root_integration_with_ai: 0
  }
};

export default withDependency(RootIntegrationField);
