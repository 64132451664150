import isEmpty from 'lodash/isEmpty';
import { get } from 'lodash';
import React from 'react';
import Batching from '../columns/Batching';
import PatientColumn from '../columns/PatientColumn';
import DateColumn from '../columns/DateColumn';
import { formatProductionTime } from '../../../helpers/techWorkflow';
import RequestsColumn from '../columns/RequestsColumn';
import { SORT_VALUE as BUNDLE_SHIPMENT_SORT_VALUE } from '../components/ShipmentBundling/constants';
import ShippingColumn from '../columns/ShippingColumn';
import BatchColumn from '../columns/BatchColumn';

export default (
  only,
  {
    isCollapsed,
    additionalParams,
    handleTrackingNumberSearch,
    batchedCases,
    setBatchedCases,
    isShipmentBundlingEnabled
  }
) => {
  const columns = [
    {
      id: 'batching',
      label: '',
      width: '50px',
      formatMethod: (value, row) => {
        return <Batching row={row} batchedCases={batchedCases} setBatchedCases={setBatchedCases} />;
      }
    },
    {
      id: 'patient',
      label: 'Patient',
      customSortId: 'last_name',
      formatMethod: (value, row) => {
        return (
          <PatientColumn
            collapsed={isCollapsed(row.id)}
            patient={row}
            doctor={row.user}
            organization={row.organization}
          />
        );
      }
    },
    {
      id: 'date',
      label: 'Dates',
      customSortId: 'created_at',
      formatMethod: (value, row) => {
        return (
          <DateColumn
            started={row.created_at}
            modified={row.updated_at}
            completed={row.setup_completed_at}
            collapsed={isCollapsed(row.id)}
            completedLabel={
              row.workflow_data.print && !isEmpty(row.workflow_data.print)
                ? 'Approved'
                : 'Completed'
            }
          />
        );
      }
    },
    {
      id: 'production_time',
      label: 'Prod. Time',
      width: '100px',
      align: 'center',
      customSortId: 'overall_production_time',
      formatMethod: (value, row) => formatProductionTime(row.overall_production_time)
    },
    {
      id: 'requests',
      label: 'Requests',
      customSortId: 'request_status_updated_at',
      formatMethod: (value, row) => {
        const requestFilter = additionalParams.params.requestTypeStatus;
        const requests = [];
        if (requestFilter.length) {
          const [request] = requestFilter[0].split('.');
          requests.push(request);
        }
        return (
          <RequestsColumn
            collapsed={isCollapsed(row.id)}
            workflowData={row.workflow_data}
            requests={requests}
            assignments={additionalParams.params.requestAssignments}
          />
        );
      }
    },
    {
      id: 'batch',
      label: 'Batch',
      disabledSorting: true,
      formatMethod: (value, row) => {
        const batchId = get(row, 'customer_request.printing_request.batch_id');
        return <BatchColumn batchId={batchId} />;
      }
    },
    {
      id: 'shipping',
      label: 'Shipping',
      customSortId: isShipmentBundlingEnabled ? BUNDLE_SHIPMENT_SORT_VALUE : 'ship_date_sort',
      formatMethod: (value, row) => {
        return get(row, 'workflow_data.print.shipping_method') ? (
          <ShippingColumn
            collapsed={isCollapsed(row.id)}
            printingRequest={row.customer_request && row.customer_request.printing_request}
            onClickTrackingNumber={handleTrackingNumberSearch}
          />
        ) : (
          ''
        );
      }
    }
  ];

  const selectedColumns = only.map(column => columns.find(item => item.id === column));

  selectedColumns.forEach(column => {
    if (column.disabledSorting) {
      return;
    }
    // disable column sort except for shipping if bundling shipment is enabled
    // eslint-disable-next-line no-param-reassign
    column.disabledSorting = () => {
      const sort = column.customSortId || column.id;
      return (
        isShipmentBundlingEnabled && !['ship_date_sort', BUNDLE_SHIPMENT_SORT_VALUE].includes(sort)
      );
    };
  });

  return selectedColumns;
};
