import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { DoneAll, Edit as EditIcon, LocalShipping, Print } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import ShippingLabel from '../../../../components/Shipment/Labels/ShippingLabel';
import { ASSIGNMENTS } from '../../../../components/RxForm/constants';
import {
  selectPatientsForBundling,
  showShippingLabel,
  showUpdatedBundledShippingAction
} from '../../../../reducers/patients';

const styles = makeStyles(theme => ({
  tableBody: {
    '&.hovered': {
      backgroundColor: theme.palette.action.hover
    },
    '&.rush': {
      backgroundColor: '#fff0db'
    },
    '&.rush.hovered': {
      backgroundColor: '#ffe2b7'
    }
  }
}));

const DefaultView = ({
  rows,
  keyValue,
  location,
  shippingMethod,
  completed,
  shippingLabelPrinted,
  patientsLabelPrinted,
  rowRenderer,
  assignment,
  hasRushCase,
  courier
}) => {
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const classesNew = styles();
  const classNames = [classesNew.tableBody];
  if (hovered) {
    classNames.push('hovered');
  }
  if (hasRushCase && !completed) {
    classNames.push('rush');
  }

  const handlePrintShippingLabel = () => {
    dispatch(showShippingLabel(rows));
  };

  const handleBundleCompletion = () => {
    dispatch(selectPatientsForBundling(rows));
  };

  const handleUpdateBundledShipping = () => {
    dispatch(showUpdatedBundledShippingAction(rows));
  };

  return (
    <TableBody key={keyValue} className={classNames.join(' ')}>
      {rows.map((item, index) => rowRenderer(item, index))}
      <TableRow
        key={`shipping-label-${keyValue}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <TableCell colSpan={7}>
          {assignment === ASSIGNMENTS.THIRD_PARTY && (
            <Typography variant="body1">This is assigned to third party partner.</Typography>
          )}
          {assignment === ASSIGNMENTS.ORTHOSELECT && !location && (
            <Typography variant="body1">Shipping location is missing.</Typography>
          )}
          {assignment === ASSIGNMENTS.ORTHOSELECT && location && (
            <div
              style={{
                marginTop: 8,
                marginBottom: 32
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 16
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <LocalShipping color="primary" />
                      &nbsp;
                      <Typography variant="body1" component="span">
                        Ship To:
                      </Typography>
                    </div>
                    <Button
                      size="small"
                      variant={shippingLabelPrinted ? 'outlined' : 'contained'}
                      color="primary"
                      disabled={shippingLabelPrinted}
                      startIcon={<Print />}
                      onClick={handlePrintShippingLabel}
                    >
                      {shippingLabelPrinted ? 'Printed' : 'Print'}
                    </Button>
                  </div>
                </div>
                <Typography
                  variant="body2"
                  component="div"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                  }}
                >
                  {!!location && <ShippingLabel location={location} />}
                </Typography>
                <div
                  style={{
                    marginLeft: 'auto',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    display: 'flex'
                  }}
                >
                  <Typography variant="body1" component="span">
                    {shippingMethod}
                  </Typography>
                  <Button
                    size="small"
                    variant={completed ? 'outlined' : 'contained'}
                    color="primary"
                    disabled={completed || !shippingLabelPrinted || !patientsLabelPrinted}
                    startIcon={<DoneAll />}
                    onClick={handleBundleCompletion}
                  >
                    {completed ? 'Completed' : 'Complete'}
                  </Button>
                  {courier !== null && (
                    <div>
                      Track:{' '}
                      <a href={courier.link} rel="noopener noreferrer" target="_blank">
                        {courier.name}
                      </a>
                      {completed && (
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={handleUpdateBundledShipping}
                        >
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

DefaultView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired,
  keyValue: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  shippingMethod: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  shippingLabelPrinted: PropTypes.bool.isRequired,
  patientsLabelPrinted: PropTypes.bool.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  assignment: PropTypes.number.isRequired,
  hasRushCase: PropTypes.bool.isRequired,
  courier: PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })
};

DefaultView.defaultProps = {
  location: null,
  courier: null
};

export default DefaultView;
