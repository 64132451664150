import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '../components/Onboarding/Modal';
import { selectCurrentOrganizationOnboarding } from '../reducers/onboardings';

export default Component => {
  return props => {
    const onboarding = useSelector(state => selectCurrentOrganizationOnboarding(state));
    const isDoctorsView = useSelector(state => !state.auth.isManagement);
    const ongoingOnboarding = isDoctorsView && onboarding && onboarding.status !== 'Completed';

    return (
      <Component
        onboarding={{
          inProgress: ongoingOnboarding,
          currentStep: ongoingOnboarding ? onboarding.current_step : null,
          modal: <Modal />
        }}
        {...props}
      />
    );
  };
};
