import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

const useStyles = makeStyles(() => ({
  warningText: {
    color: orange[700]
  },
  formattedText: {
    color: green[500],
    fontWeight: 'bold'
  }
}));

const SanitizeForbiddenCharactersField = forwardRef(
  ({ field, form, originalComponent: Component, ...props }, ref) => {
    const classes = useStyles();
    const globalSettings = useSelector(state => state.settings.global || {});

    const replaceWithDash = str => {
      const { forbidden_characters: forbiddenChars, replacement } = _get(
        globalSettings,
        'name_correction',
        {
          replacement: null,
          forbidden_characters: []
        }
      );

      if (!replacement || forbiddenChars.length === 0) {
        return str;
      }

      const regex = new RegExp(`[${forbiddenChars.join('\\')}]`, 'g');
      return str.replace(regex, replacement);
    };

    let textHelper = null;
    let textHelperClassName;

    if (field.value && replaceWithDash(field.value) !== field.value) {
      textHelper = (
        <>
          Warning! This field has restricted characters. It will be automatically translated to:{' '}
          <span className={classes.formattedText}>{replaceWithDash(field.value)}</span>
        </>
      );
      textHelperClassName = classes.warningText;
    } else if (form.touched[field.name] && form.errors[field.name]) {
      textHelper = form.errors[field.name];
    }
    return (
      <Component
        form={form}
        field={{
          ...field,
          onBlur: e => {
            const sanitizedValue = replaceWithDash(e.target.value);
            if (e.target.value !== sanitizedValue) {
              form.setFieldValue(field.name, sanitizedValue);
            }
            form.setFieldTouched(field.name, true);
          }
        }}
        {...props}
        ref={ref}
        helperText={textHelper}
        FormHelperTextProps={{ className: textHelperClassName }}
      />
    );
  }
);

SanitizeForbiddenCharactersField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  originalComponent: PropTypes.elementType.isRequired
};

export default SanitizeForbiddenCharactersField;
