import { extractRequestType } from '../../../../reducers/patients';
import { REQUEST, REQUEST_STATUS } from '../../../../constants';
import { ASSIGNMENTS } from '../../../../components/RxForm/constants';
import { SORT_VALUE } from './constants';
import { LAYOUT_BATCH_MODE, LAYOUT_DEFAULT } from '../../layout';

function isApplicable(layout, parameters) {
  const isPrint = extractRequestType(parameters) === REQUEST.PRINT;
  const { requestAssignments, shipping, requestTypeStatus, trackingNumber } = parameters;
  const isAssignedToOrthoSelectOnly =
    requestAssignments.length === 1 && requestAssignments[0] === ASSIGNMENTS.ORTHOSELECT;
  const isCompletedOnly =
    requestTypeStatus.length === 1 &&
    requestTypeStatus[0] === `${REQUEST.PRINT}.${REQUEST_STATUS.COMPLETED}`;

  return (
    layout === LAYOUT_BATCH_MODE ||
    (layout === LAYOUT_DEFAULT &&
      isPrint &&
      isAssignedToOrthoSelectOnly &&
      (shipping || isCompletedOnly || trackingNumber))
  );
}

export function autoEnableShipmentBundling(layout, parameters) {
  // enabled
  const applicable = isApplicable(layout, parameters);
  if (applicable) {
    // eslint-disable-next-line no-param-reassign
    parameters.orderBy = SORT_VALUE;
    if (layout === LAYOUT_DEFAULT) {
      parameters.includes.push('customer_request.printing_request.bundled_shipment.shipping_cost');
    }
  }

  // disable
  if (!applicable && parameters.orderBy === SORT_VALUE) {
    // eslint-disable-next-line no-param-reassign
    parameters.orderBy = 'ship_date_sort';
    // eslint-disable-next-line no-param-reassign
    parameters.order = 'asc';
  }
}
