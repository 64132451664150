import React from 'react';
import PropTypes from 'prop-types';
import { PRINTING_REQUESTS_STATUSES_ID } from '../../../../constants';
import DefaultView from './DefaultView';
import { LAYOUT_DEFAULT } from '../../layout';
import BatchView from './BatchView';

const otherGroupProps = rows => {
  return {
    completed:
      rows.filter(
        patient =>
          patient.customer_request.printing_request.printing_request_status_id ===
          PRINTING_REQUESTS_STATUSES_ID.completed
      ).length === rows.length,
    shippingLabelPrinted:
      rows.filter(
        patient => patient.customer_request.printing_request.shipping_label_printed === true
      ).length === rows.length,
    patientsLabelPrinted:
      rows.filter(
        patient => patient.customer_request.printing_request.patient_label_printed === true
      ).length === rows.length
  };
};

const groupPatients = rows => {
  const groups = {};
  const orderedGroups = [];

  rows.forEach(value => {
    const printingRequest = value.customer_request.printing_request;

    const groupData = {
      location: printingRequest.location,
      shippingMethod: printingRequest.shipping_method.name,
      shipDate: printingRequest.ship_date,
      assignment: printingRequest.assigned_to,
      courier: printingRequest.courier,
      organization: value.organization
    };
    const groupId = value.bundled_shipment_id || `patient-${value.id}`;
    const isRush = value.rx_form !== null && value.rx_form.rush_case;

    if (groups[groupId]) {
      const currentGroup = groups[groupId];
      currentGroup.rows.push(value);
      if (currentGroup.shippingMethod !== groupData.shippingMethod && currentGroup.bundledMethod) {
        currentGroup.shippingMethod = 'Mixed';
        currentGroup.bundledMethod = true;
      }
      currentGroup.hasRushCase = currentGroup.hasRushCase || isRush;
    } else {
      const group = {
        ...groupData,
        bundledMethod: false,
        rows: [value],
        keyValue: groupId,
        hasRushCase: isRush
      };
      orderedGroups.push(group);
      groups[groupId] = group;
    }
  });

  return orderedGroups.map(group => {
    const otherProps = otherGroupProps(group.rows);
    return {
      ...group,
      ...otherProps
    };
  });
};

const ShipmentBundling = ({ layout, rows, ...props }) => {
  const groups = groupPatients(rows);

  const Component = layout === LAYOUT_DEFAULT ? DefaultView : BatchView;

  return groups.map(groupProps => {
    return <Component {...props} {...groupProps} />;
  });
};

ShipmentBundling.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired
};

export default ShipmentBundling;
