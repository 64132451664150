import React from 'react';
import PropTypes from 'prop-types';

const BatchColumn = ({ batchId }) => {
  if (!batchId) {
    return (
      <span
        style={{
          fontSize: 12,
          fontStyle: 'italic',
          color: 'grey'
        }}
      >
        N/A
      </span>
    );
  }
  return (
    <a target="_blank" href={`/batches/${batchId}`} rel="noopener noreferrer">
      {batchId}
    </a>
  );
};

BatchColumn.propTypes = {
  batchId: PropTypes.number
};

BatchColumn.defaultProps = {
  batchId: null
};

export default BatchColumn;
