import React from 'react';
import { Form, Formik } from 'formik';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { updateBracketLibrary } from '../../../../../reducers/bracketLibraries';
import { enqueueNotification } from '../../../../../reducers/notifications';
import IntegerInput from './IntegerInput';

const useStyles = makeStyles(() => ({
  inputBox: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 50px',
    width: '250px',
    alignItems: 'flex-start',
    '&  > .MuiFormControl-root:nth-child(2) .MuiOutlinedInput-root': {
      '&.Mui-error fieldset': {
        borderLeftColor: '#f44336',
        borderRightColor: '#f44336'
      },
      '& fieldset': {
        borderLeftColor: '#fff',
        borderRightColor: '#fff'
      },
      '&:hover fieldset': {
        borderLeftColor: '#000',
        borderRightColor: '#000'
      },
      '&.Mui-focused fieldset': {
        borderLeftColor: '#008CD2',
        borderRightColor: '#008CD2'
      }
    }
  },
  buttonGroup: {
    position: 'absolute',
    right: '-10px',
    top: '4px',
    '& > .MuiIconButton-root': {
      padding: '2px'
    },
    zIndex: '5'
  }
}));

const ShelfRowColumnInput = ({ bracket, organizationId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSubmit = (values, resetForm, setSubmitting) => {
    const data = {};

    Object.keys(values).forEach(key => {
      if (values[key] === '') {
        data[key] = null;
      } else {
        data[key] = values[key];
      }
    });

    dispatch(updateBracketLibrary(organizationId, bracket.id, data))
      .then(() => {
        dispatch(enqueueNotification('success', 'The bracket has been successfully updated.'));
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const validationSchema = Yup.object().shape({
    shelf: Yup.number()
      .typeError('Must be an integer')
      .min(0, 'Minimum of 0')
      .max(255, 'Maximum of 255')
      .nullable(),
    row: Yup.number()
      .typeError('Must be an integer')
      .min(0, 'Minimum of 0')
      .max(255, 'Maximum of 255')
      .nullable(),
    column: Yup.number()
      .typeError('Must be an integer')
      .min(0, 'Minimum of 0')
      .max(255, 'Maximum of 255')
      .nullable()
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          shelf: bracket.shelf || '',
          row: bracket.row || '',
          column: bracket.column || ''
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          handleSubmit(values, resetForm, setSubmitting);
        }}
      >
        {({ isSubmitting, dirty, resetForm, submitForm }) => (
          <Form>
            <Box display="flex" gap={0} className={classes.inputBox}>
              <IntegerInput
                name="shelf"
                TextFieldProps={{
                  disabled: isSubmitting,
                  helperText: isSubmitting ? 'Saving...' : null
                }}
              />
              <IntegerInput name="row" TextFieldProps={{ disabled: isSubmitting }} />
              <IntegerInput name="column" TextFieldProps={{ disabled: isSubmitting }} />
              {dirty && (
                <Box display="flex" gap={0} flexDirection="row" className={classes.buttonGroup}>
                  <Tooltip title="Save">
                    <IconButton color="primary" onClick={submitForm} disabled={isSubmitting}>
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton
                      color="secondary"
                      onClick={() => resetForm()}
                      disabled={isSubmitting}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

ShelfRowColumnInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bracket: PropTypes.object.isRequired,
  organizationId: PropTypes.number.isRequired
};

export default ShelfRowColumnInput;
