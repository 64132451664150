import { get } from 'lodash';
import WorkIcon from '@material-ui/icons/WorkSharp';
import WorkOutlineIcon from '@material-ui/icons/WorkOutlineSharp';
import { Print } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpSharp';
import React from 'react';
import { showPatientLabel } from '../../../reducers/patients';
import NoteStatus from '../components/NoteStatus';
import ScannerType from '../components/ScannerType';
import TrackingNumber from '../components/TrackingNumber';

export default ({
  externalAppOpener,
  history,
  dispatch,
  classes,
  handleTrackingNumberSearch,
  isCollapsed,
  toggleCollapse,
  isShipmentBundlingEnabled
}) => {
  const handleShowPatientWorkflow = id => {
    history.push(`/patient-workflow/${id}`);
  };

  const isClaimed = row => {
    const current = get(row, 'workflow_data.current', null);

    if (!current) {
      return false;
    }

    const nextStep = get(row, `workflow_data.${current}.next_step`, null);

    return nextStep && (nextStep.tech_name || nextStep.time_start || nextStep.time_end);
  };

  return [
    externalAppOpener,
    {
      name: 'details',
      handleOnAction: handleShowPatientWorkflow,
      title: 'Workflows',
      isIconButton: true,
      className: row => {
        let c = classes.compactButton;

        if (isClaimed(row)) {
          c = `${c} ${classes.greenButton}`;
        }
        return c;
      },
      icon: row => (isClaimed(row) ? <WorkIcon /> : <WorkOutlineIcon />)
    },
    {
      name: 'patient_label',
      handleOnAction: (id, patient) => {
        dispatch(showPatientLabel(patient));
      },
      title: 'Patient Label',
      isIconButton: true,
      className: classes.compactButton,
      icon: <Print />,
      condition: () => {
        return isShipmentBundlingEnabled;
      }
    },
    {
      name: 'more',
      handleOnAction: toggleCollapse,
      title: 'Show More',
      isIconButton: true,
      className: `${classes.compactButton} collapse-button`,
      icon: <KeyboardArrowDownIcon />,
      condition: row => !isCollapsed(row.id)
    },
    {
      name: 'hide',
      handleOnAction: toggleCollapse,
      title: 'Hide',
      isIconButton: true,
      className: `${classes.compactButton} collapse-button`,
      icon: <KeyboardArrowUpIcon />,
      condition: row => isCollapsed(row.id)
    },
    {
      name: 'noteStatus',
      title: 'Note Status',
      component: row => (
        <NoteStatus
          key={row.id}
          hasDoctorNotes={row.notes_availability.doctor}
          hasInternalNotes={row.notes_availability.internal}
          hasManagementNotes={row.notes_availability.tech}
          hasOnHoldNotes={row.notes_availability.onhold}
          hasPhysicalTechNotes={row.notes_availability.physical_tech}
        />
      ),
      condition: () => true
    },
    {
      name: 'scannerType',
      title: 'Scanner Type',
      component: row => <ScannerType rxForm={row.rx_form} />,
      condition: row => isCollapsed(row.id)
    },
    {
      name: 'trackingNumber',
      title: 'Tracking Number',
      component: row => (
        <TrackingNumber
          printingRequest={get(row, 'customer_request.printing_request')}
          onClickTrackingNumber={handleTrackingNumberSearch}
        />
      ),
      condition: row => isCollapsed(row.id)
    }
  ];
};
