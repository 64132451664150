import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { LAYOUT_DEFAULT, LAYOUT_KEYWORDS_ONLY } from '../../layout';

const KeywordsOnly = ({ layout, setLayout }) => {
  return (
    <FormControlLabel
      checked={layout === LAYOUT_KEYWORDS_ONLY}
      onChange={() => {
        const newLayout = layout === LAYOUT_KEYWORDS_ONLY ? LAYOUT_DEFAULT : LAYOUT_KEYWORDS_ONLY;
        setLayout(newLayout);
      }}
      control={<Switch color="primary" />}
      label="Search By Keyword Only"
    />
  );
};

KeywordsOnly.propTypes = {};

export default KeywordsOnly;
