import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import BracketLibrariesApiService from '../services/api/bracketLibraries';

export const FETCHING_BRACKET_LIBRARIES = 'FETCHING_BRACKET_LIBRARIES';
export const FETCHED_BRACKET_LIBRARIES = 'FETCHED_BRACKET_LIBRARIES';
export const FETCHING_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS =
  'FETCHING_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS';
export const FETCHED_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS =
  'FETCHED_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS';
export const UPLOADING_BRACKET_LIBRARY = 'UPLOADING_BRACKET_LIBRARY';
export const UPLOADED_BRACKET_LIBRARY = 'UPLOADED_BRACKET_LIBRARY';
export const UPDATING_BRACKET_LIBRARY = 'UPDATING_BRACKET_LIBRARY';
export const UPDATED_BRACKET_LIBRARY = 'UPDATED_BRACKET_LIBRARY';
export const REMOVING_BRACKET_LIBRARY = 'REMOVING_BRACKET_LIBRARY';
export const REMOVED_BRACKET_LIBRARY = 'REMOVED_BRACKET_LIBRARY';

export const RESET_BRACKET_LIBRARIES = 'RESET_BRACKET_LIBRARIES';

export const FETCHING_USER_BRACKET_LIBRARIES = 'FETCHING_USER_BRACKET_LIBRARIES';
export const FETCHED_USER_BRACKET_LIBRARIES = 'FETCHED_USER_BRACKET_LIBRARIES';

export const RESET_BRACKET_LIBRARIES_FOR_USERS = 'RESET_BRACKET_LIBRARIES_FOR_USERS';

const initialState = {
  organizations: {},
  users: {}
};

const sortByFileName = items => {
  items.sort((item1, item2) => (item1.file_name < item2.file_name ? -1 : 1));
};

export default (state = initialState, { type, payload = null }) => {
  switch (type) {
    case FETCHING_BRACKET_LIBRARIES:
    case FETCHING_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS:
      return produce(state, draft => {
        if (!draft.organizations[payload.organization_id]) {
          draft.organizations[payload.organization_id] = {
            fetched: false,
            items: [],
            assignableMembers: [],
            uploading: [],
            fetching: false,
            removing: [],
            updating: false
          };
        }
        draft.organizations[payload.organization_id].fetching = true;
        draft.organizations[payload.organization_id].items = [];
      });

    case FETCHED_BRACKET_LIBRARIES:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].fetching = false;
        draft.organizations[payload.organization_id].fetched = true;
        draft.organizations[payload.organization_id].items = payload.items;
        sortByFileName(draft.organizations[payload.organization_id].items);
      });

    case RESET_BRACKET_LIBRARIES:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].fetched = false;
      });

    case FETCHED_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].fetching = false;
        draft.organizations[payload.organization_id].fetched = true;
        draft.organizations[payload.organization_id].assignableMembers = payload.items;
      });

    case UPLOADING_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].uploading.push(payload.file);
      });

    case UPLOADED_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].items.push(payload.item);
        sortByFileName(draft.organizations[payload.organization_id].items);
        const { uploading } = state.organizations[payload.organization_id];
        draft.organizations[payload.organization_id].uploading = uploading.filter(
          item => item !== payload.file
        );
      });

    case UPDATING_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].updating = true;
      });

    case UPDATED_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].updating = false;
        draft.organizations[payload.organization_id].items = draft.organizations[
          payload.organization_id
        ].items.map(item => {
          if (item.id === payload.item.id) {
            return payload.item;
          }
          return item;
        });
      });

    case REMOVING_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].removing.push(payload.item_id);
      });

    case REMOVED_BRACKET_LIBRARY:
      return produce(state, draft => {
        draft.organizations[payload.organization_id].items = state.organizations[
          payload.organization_id
        ].items.filter(item => item.id !== payload.item_id);
        draft.organizations[payload.organization_id].removing = state.organizations[
          payload.organization_id
        ].removing.filter(item => item.id !== payload.item_id);
      });

    case FETCHING_USER_BRACKET_LIBRARIES: {
      return produce(state, draft => {
        draft.users[payload.user_id] = {
          [payload.organization_id]: {
            fetched: false,
            items: [],
            fetching: true
          }
        };
      });
    }

    case FETCHED_USER_BRACKET_LIBRARIES: {
      return produce(state, draft => {
        draft.users[payload.user_id][payload.organization_id] = {
          fetched: true,
          items: payload.items,
          fetching: false
        };
        sortByFileName(draft.users[payload.user_id][payload.organization_id].items);
      });
    }

    case RESET_BRACKET_LIBRARIES_FOR_USERS: {
      return produce(state, draft => {
        Object.keys(draft.users).forEach(userId => {
          if (draft.users[userId][payload.organization_id]) {
            draft.users[userId][payload.organization_id].fetched = false;
          }
        });
      });
    }

    default:
      return state;
  }
};

export function fetchBracketLibraries(organizationId) {
  return dispatch => {
    dispatch({ type: FETCHING_BRACKET_LIBRARIES, payload: { organization_id: organizationId } });
    const api = new BracketLibrariesApiService();
    return api
      .getBracketLibraries(organizationId)
      .then(({ data }) => {
        dispatch({
          type: FETCHED_BRACKET_LIBRARIES,
          payload: {
            organization_id: organizationId,
            items: data
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function fetchBracketLibraryAssignableMembers(organizationId) {
  return dispatch => {
    dispatch({
      type: FETCHING_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS,
      payload: { organization_id: organizationId }
    });
    const api = new BracketLibrariesApiService();
    return api
      .getBracketLibraryAssignableMembers(organizationId)
      .then(data => {
        dispatch({
          type: FETCHED_BRACKET_LIBRARY_ASSIGNABLE_MEMBERS,
          payload: {
            organization_id: organizationId,
            items: data
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function deleteBracketLibrary(organizationId, id) {
  return dispatch => {
    dispatch({
      type: REMOVING_BRACKET_LIBRARY,
      payload: { organization_id: organizationId, item_id: id }
    });
    const api = new BracketLibrariesApiService();
    return api
      .deleteBracketLibrary(organizationId, id)
      .then(() => {
        dispatch({
          type: REMOVED_BRACKET_LIBRARY,
          payload: {
            organization_id: organizationId,
            item_id: id
          }
        });
        dispatch(enqueueNotification('success', `Bracket library has been successfully removed.`));
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function uploadBracketLibrary(organizationId, file, format) {
  return dispatch => {
    dispatch({
      type: UPLOADING_BRACKET_LIBRARY,
      payload: { organization_id: organizationId, file }
    });
    const api = new BracketLibrariesApiService();
    return api
      .storeBracketLibrary(organizationId, file, format)
      .then(({ data }) => {
        dispatch({
          type: UPLOADED_BRACKET_LIBRARY,
          payload: {
            organization_id: organizationId,
            item: data,
            file
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateBracketLibrary(organizationId, id, data) {
  return dispatch => {
    dispatch({
      type: UPDATING_BRACKET_LIBRARY,
      payload: { organization_id: organizationId }
    });
    const api = new BracketLibrariesApiService();

    return new Promise((resolve, reject) => {
      api
        .updateBracketLibrary(organizationId, id, data)
        .then(({ data: response }) => {
          dispatch({
            type: UPDATED_BRACKET_LIBRARY,
            payload: {
              organization_id: organizationId,
              item: response
            }
          });
          resolve(response);
        })
        .catch(error => {
          dispatch(enqueueNotification('error', error.message));
          reject(error);
        });
    });
  };
}

export function resetBracketLibraries(organizationId) {
  return dispatch => {
    dispatch({
      type: RESET_BRACKET_LIBRARIES,
      payload: { organization_id: organizationId }
    });
  };
}

export function fetchUserBracketLibraries(organizationId, userId) {
  return dispatch => {
    dispatch({
      type: FETCHING_USER_BRACKET_LIBRARIES,
      payload: { organization_id: organizationId, user_id: userId }
    });
    const api = new BracketLibrariesApiService();
    return api
      .getBracketLibrariesForUser(organizationId, userId)
      .then(({ data }) => {
        dispatch({
          type: FETCHED_USER_BRACKET_LIBRARIES,
          payload: {
            organization_id: organizationId,
            user_id: userId,
            items: data
          }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function resetBracketLibrariesForUsers(organizationId) {
  return dispatch => {
    dispatch({
      type: RESET_BRACKET_LIBRARIES_FOR_USERS,
      payload: { organization_id: organizationId }
    });
  };
}
