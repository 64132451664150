import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popup from './Popup';
import { getCase, rate as rateFn } from '../../reducers/caseFeedback';

/**
 * Responsible for the retrieval of suggested case
 */
const Main = ({ fetched, patient, showDisableOption, getPatient, rate, submitting }) => {
  useEffect(() => {
    if (!fetched && !submitting) {
      getPatient();
    }
  }, [fetched, getPatient, submitting]);

  return (
    <>
      {!!patient && (
        <Popup showOptionToTurnOff={showDisableOption} name={patient.name} rate={rate} />
      )}
    </>
  );
};

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object,
  showDisableOption: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  getPatient: PropTypes.func.isRequired,
  rate: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

Main.defaultProps = {
  patient: null
};

export default connect(
  (state, props) => {
    let fetched;
    let submitting;
    if (state.caseFeedback.organizationId !== props.organizationId) {
      fetched = false;
      submitting = false;
    } else {
      fetched = state.caseFeedback.fetched;
      submitting = state.caseFeedback.submitting;
    }
    return {
      patient: state.caseFeedback.patient,
      showDisableOption: state.caseFeedback.showDisableOption,
      fetched,
      submitting
    };
  },
  (dispatch, props) => {
    return {
      getPatient: () => {
        dispatch(getCase(props.organizationId));
      },
      rate: (score, options) => dispatch(rateFn(score, options))
    };
  }
)(Main);
