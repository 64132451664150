import { produce } from 'immer';
import { enqueueNotification } from './notifications';
import BatchApiService from '../services/api/batches';

export const FETCHING_BATCHES_REQUEST_ACTION = 'FETCHING_BATCHES_REQUEST_ACTION';
export const FETCHING_BATCHES_SUCCESS_ACTION = 'FETCHING_BATCHES_SUCCESS_ACTION';

export const CREATE_BATCH_REQUEST_ACTION = 'CREATE_BATCH_REQUEST_ACTION';
export const CREATE_BATCH_SUCCESS_ACTION = 'CREATE_BATCH_SUCCESS_ACTION';

export const UPDATE_BATCH_REQUEST_ACTION = 'UPDATE_BATCH_REQUEST_ACTION';
export const UPDATE_BATCH_SUCCESS_ACTION = 'UPDATE_BATCH_SUCCESS_ACTION';
export const UPDATE_BATCH_FAILED_ACTION = 'UPDATE_BATCH_FAILED_ACTION';

export const RETRIEVE_BATCH_REQUEST_ACTION = 'RETRIEVE_BATCH_REQUEST_ACTION';
export const RETRIEVE_BATCH_SUCCESS_ACTION = 'RETRIEVE_BATCH_SUCCESS_ACTION';

export const REMOVE_CASE_REQUEST_ACTION = 'REMOVE_CASE_REQUEST_ACTION';
export const REMOVE_CASE_SUCCESS_ACTION = 'REMOVE_CASE_SUCCESS_ACTION';

export const INITIAL_LIST_PARAMETERS = {
  orderBy: 'created_at',
  order: 'asc',
  page: 1,
  perPage: 10,
  status: 'new',
  searchByName: ''
};

const initialState = {
  creating: false,
  show: {
    updating: false
  },
  listing: {
    fetching: false,
    data: [],
    total: null,
    parameters: {
      ...INITIAL_LIST_PARAMETERS
    }
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_BATCH_REQUEST_ACTION:
      return { ...state, creating: true };
    case CREATE_BATCH_SUCCESS_ACTION:
      return { ...state, creating: false };
    case FETCHING_BATCHES_REQUEST_ACTION:
      return produce(state, draft => {
        draft.listing.fetching = true;
        draft.listing.parameters = payload;
      });
    case FETCHING_BATCHES_SUCCESS_ACTION: {
      return produce(state, draft => {
        draft.listing.fetching = false;
        draft.listing.data = payload.data;
        draft.listing.total = payload.meta.total;
      });
    }
    case RETRIEVE_BATCH_SUCCESS_ACTION: {
      return produce(state, draft => {
        draft.show.fetching = false;
        const index = draft.listing.data.findIndex(batch => batch.id === payload.id);
        if (index !== -1) {
          draft.listing.data[index] = payload;
        } else {
          draft.listing.data.push(payload);
        }
      });
    }
    case UPDATE_BATCH_REQUEST_ACTION:
      return produce(state, draft => {
        draft.show.updating = true;
      });
    case UPDATE_BATCH_SUCCESS_ACTION: {
      return produce(state, draft => {
        draft.show.updating = false;
        const index = draft.listing.data.findIndex(batch => batch.id === payload.id);
        if (index !== -1) {
          draft.listing.data[index] = payload;
        }
      });
    }
    case UPDATE_BATCH_FAILED_ACTION: {
      return produce(state, draft => {
        draft.show.updating = false;
      });
    }
    case REMOVE_CASE_SUCCESS_ACTION: {
      return produce(state, draft => {
        const batchIndex = draft.listing.data.findIndex(batch => batch.id === payload.batchId);
        if (batchIndex !== -1) {
          const caseIndex = draft.listing.data[batchIndex].cases.findIndex(
            _case => _case.id === payload.caseId
          );
          if (caseIndex !== -1) {
            draft.listing.data[batchIndex].cases.splice(caseIndex, 1);
          }
        }
      });
    }
    default:
      return state;
  }
};

export function createBatchAction(patients, name = null) {
  return dispatch => {
    dispatch({ type: CREATE_BATCH_REQUEST_ACTION });
    const service = new BatchApiService();
    return service
      .create({ patients, name })
      .then(response => {
        dispatch({ type: CREATE_BATCH_SUCCESS_ACTION });
        dispatch(
          enqueueNotification(
            'success',
            response.message,
            { autoHideDuration: 5000 },
            {
              link: `/batches/${response.data.id}`,
              text: 'Visit Batch',
              target: '_blank'
            }
          )
        );
        return response;
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
        return new Promise((resolve, reject) => {
          reject(error.message);
        });
      });
  };
}

export function fetchBatchesAction(parameters) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.batches.listing.fetching) {
      return;
    }

    dispatch({ type: FETCHING_BATCHES_REQUEST_ACTION, payload: parameters });
    const service = new BatchApiService();
    return service
      .get(parameters)
      .then(response => {
        dispatch({ type: FETCHING_BATCHES_SUCCESS_ACTION, payload: response });
        return response;
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
        return new Promise((resolve, reject) => {
          reject(error.message);
        });
      });
  };
}

export const fetchBatchAction = id => {
  return dispatch => {
    dispatch({ type: RETRIEVE_BATCH_REQUEST_ACTION });
    const service = new BatchApiService();
    return service
      .show(id)
      .then(response => {
        dispatch({ type: RETRIEVE_BATCH_SUCCESS_ACTION, payload: response.data });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
};

export function updateBatchAction(id, data) {
  return dispatch => {
    dispatch({ type: UPDATE_BATCH_REQUEST_ACTION });
    const service = new BatchApiService();
    return service
      .update(id, data)
      .then(response => {
        dispatch({ type: UPDATE_BATCH_SUCCESS_ACTION, payload: response.data });
        dispatch(enqueueNotification('success', 'Batch updated successfully'));
        return response;
      })
      .catch(error => {
        dispatch({ type: UPDATE_BATCH_FAILED_ACTION });
        dispatch(enqueueNotification('error', error.message));
        return new Promise((resolve, reject) => {
          reject(error.message);
        });
      });
  };
}

export function removeCaseAction(batchId, caseId) {
  return dispatch => {
    const service = new BatchApiService();
    dispatch({ type: REMOVE_CASE_REQUEST_ACTION, payload: { batchId, caseId } });
    return service.removeCase(batchId, caseId).then(response => {
      dispatch({ type: REMOVE_CASE_SUCCESS_ACTION, payload: { batchId, caseId } });
      dispatch(enqueueNotification('success', response.message));
      return response;
    });
  };
}
