import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useSelector } from 'react-redux';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BusinessIcon from '@material-ui/icons/Business';
import InventoryIcon from '@material-ui/icons/ListAlt';
import { isEmpty } from 'lodash';
import { Grain } from '@material-ui/icons';
import { Profile, SidebarNav } from './components';
import OrthoBrainLink from './components/OrthoBrainLink';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, drawerClassName, ...rest } = props;
  const currentOrganization = useSelector(state => state.auth.currentOrganization);

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      to: '/dashboard',
      profile: ['management'],
      roles: ['super_admin', 'admin', 'tech_support', 'supervisor', 'lab_partner'],
      icon: <DashboardIcon />
    },
    {
      title: 'Patients',
      to: '/patients',
      profile: ['normal'],
      icon: <AssignmentIndIcon />
    },
    {
      title: 'Patients',
      to: '/patients-page',
      profile: ['management'],
      roles: ['super_admin', 'admin', 'tech_support', 'supervisor', 'lab_partner'],
      icon: <AssignmentIndIcon />
    },
    {
      title: 'Batches',
      to: '/batches',
      profile: ['management'],
      roles: ['super_admin', 'admin', 'tech_support', 'supervisor'],
      icon: <Grain />
    },
    {
      title: 'Organizations & Users',
      to: '/organizations-users',
      profile: ['management'],
      roles: ['super_admin', 'admin', 'tech_support', 'supervisor'],
      icon: <PeopleIcon />
    },
    {
      title: 'Promotion Codes',
      to: '/promotion-codes',
      profile: ['management'],
      roles: ['super_admin', 'admin', 'supervisor'],
      icon: <LocalOfferIcon />
    },
    {
      title: 'Start a New Case',
      to: currentOrganization ? `/organizations/${currentOrganization.id}/rx-forms/add` : '',
      profile: ['normal'],
      isDisabled:
        !isEmpty(currentOrganization) &&
        !isEmpty(currentOrganization.contract) &&
        currentOrganization.contract.suspended_at,
      icon: <AssignmentIcon />
    },
    {
      title: 'Locations',
      to: '/locations',
      profile: ['normal'],
      roles: ['doctor'],
      icon: <LocationOnIcon />,
      organizationRoles: ['organization_owner', 'organization_manager']
    },
    {
      title: 'Bracket Inventory',
      to: currentOrganization ? `/organizations/${currentOrganization.id}/brackets` : '',
      profile: ['normal'],
      isDisabled: isEmpty(currentOrganization),
      roles: ['doctor'],
      icon: <InventoryIcon />,
      organizationRoles: ['organization_owner', 'organization_manager', 'organization_member']
    },
    {
      title: 'Organizations',
      to: '/organizations',
      roles: ['lab_partner'],
      profile: ['management'],
      icon: <BusinessIcon />
    },
    {
      title: 'Account',
      to: '/account',
      profile: ['normal', 'management'],
      icon: <AccountBoxIcon />
    },
    {
      title: 'Reports',
      to: '/export-reports',
      profile: ['management'],
      roles: ['super_admin'],
      icon: <DescriptionIcon />
    },
    {
      title: 'Settings',
      to: '/settings',
      profile: ['management'],
      roles: ['super_admin', 'admin', 'tech_support', 'supervisor'],
      icon: <SettingsIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: clsx([classes.drawer, drawerClassName]) }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
        <OrthoBrainLink />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  drawerClassName: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

Sidebar.defaultProps = {
  className: '',
  onClose: () => {}
};

export default Sidebar;
