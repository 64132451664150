import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { FormControlLabel } from '@material-ui/core';
import Context from './Context';

export const VALUE_CONVERSION_MODE_ONLY_WITHOUT = 'only_without';

const ToggleFilter = ({ value, label, name, onChange }) => {
  return (
    <FormControlLabel
      control={<Switch checked={value === true} onChange={onChange} name={name} />}
      label={label}
    />
  );
};

ToggleFilter.propTypes = {
  value: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

ToggleFilter.defaultProps = {
  value: false
};

const Wrapper = ({ name, valueConversionMode, nullable, ...props }) => {
  const { values, onChange } = useContext(Context);
  const handleChange = event => {
    let value;
    if (valueConversionMode === VALUE_CONVERSION_MODE_ONLY_WITHOUT) {
      value = event.target.checked ? 'only' : 'without';
    } else {
      value = event.target.checked;
    }
    if (nullable && !value) {
      value = null;
    }
    onChange(
      {
        ...values,
        [event.target.name]: value
      },
      true
    );
  };

  let value = values[name];
  if (valueConversionMode === VALUE_CONVERSION_MODE_ONLY_WITHOUT) {
    value = value === 'only';
  }

  return <ToggleFilter name={name} onChange={handleChange} value={value} {...props} />;
};

Wrapper.propTypes = {
  name: PropTypes.string.isRequired,
  valueConversionMode: PropTypes.string,
  nullable: PropTypes.bool
};

Wrapper.defaultProps = {
  valueConversionMode: 'default',
  nullable: false
};

export default Wrapper;
