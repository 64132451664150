import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import PrintCompletionDialog from './PrintCompletionDialog';
import { updateBundledShippingAction } from '../../../reducers/requests';
import {
  cancelUpdateBundledShippingAction,
  fetchSpecificPatientsOnly
} from '../../../reducers/patients';
import { COMMON_PATIENT_INCLUDES } from '../../../constants/queries';
import {
  closeGlobalBackdropAction,
  openGlobalBackdropAction
} from '../../../reducers/globalBackdrop';

const UpdateBundledShippingDialog = ({
  patients,
  submit,
  close,
  reloadPatients,
  openBackDrop,
  closeBackdrop
}) => {
  const [submittedValues, setSubmittedValues] = useState(null);

  const handleContinue = ({
    tracking_number: trackingNumber,
    custom_shipping_fee: customShippingFee,
    internal_shipping_cost: internalShippingCost
  }) => {
    openBackDrop();
    setSubmittedValues({
      tracking_number: trackingNumber,
      custom_shipping_fee: customShippingFee,
      internal_shipping_cost: internalShippingCost
    });
    submit(patients, trackingNumber, customShippingFee, internalShippingCost)
      .then(() => {
        close();
        reloadPatients(patients);
      })
      .catch(() => {})
      .finally(() => {
        setSubmittedValues(null);
        closeBackdrop();
      });
  };

  const getInitialValues = () => {
    const initial = {
      tracking_number: undefined,
      custom_shipping_fee: undefined,
      internal_shipping_cost: undefined
    };

    if (!patients || patients.length === 0) {
      return initial;
    }

    if (submittedValues) {
      return submittedValues;
    }

    const printingRequest = patients[0].customer_request.printing_request;

    return {
      tracking_number: printingRequest.tracking_number,
      custom_shipping_fee: printingRequest.custom_shipping_fee,
      internal_shipping_cost: _get(printingRequest, 'bundled_shipment.shipping_cost.cost', null)
    };
  };

  return (
    <PrintCompletionDialog
      open={patients !== null}
      onClose={close}
      onContinue={handleContinue}
      titleText="Update Bundled Shipping"
      acceptButtonText="Update"
      initialValues={getInitialValues()}
    />
  );
};

UpdateBundledShippingDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patients: PropTypes.array,
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  reloadPatients: PropTypes.func.isRequired,
  openBackDrop: PropTypes.func.isRequired,
  closeBackdrop: PropTypes.func.isRequired
};

UpdateBundledShippingDialog.defaultProps = {
  patients: null
};

export default connect(
  state => ({
    patients: state.patients.selection.updateBundledShipping
  }),
  dispatch => ({
    submit: (patients, trackingNumber, customShippingFee, internalShippingCost) => {
      const bundledShipmentId = _get(
        patients[0],
        'customer_request.printing_request.bundled_shipment.id'
      );

      return dispatch(
        updateBundledShippingAction(
          bundledShipmentId,
          trackingNumber,
          customShippingFee,
          internalShippingCost
        )
      );
    },
    close: () => dispatch(cancelUpdateBundledShippingAction()),
    reloadPatients: patients =>
      dispatch(
        fetchSpecificPatientsOnly(
          patients.map(item => item.id),
          [
            ...COMMON_PATIENT_INCLUDES,
            'customer_request.printing_request.bundled_shipment.shipping_cost'
          ]
        )
      ),
    openBackDrop: () => dispatch(openGlobalBackdropAction()),
    closeBackdrop: () => dispatch(closeGlobalBackdropAction())
  })
)(UpdateBundledShippingDialog);
