import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ShipmentBundling from './ShipmentBundling';
import { findRolesInUser } from '../../../helpers';
import { ROLES } from '../../../constants';
import { isShipmentBundlingEnabledSelector } from '../../../selectors/patients';

const RowsIterator = ({ layout, enabledGroupShipment, DefaultRowsIteratorComponent, ...props }) => {
  if (!enabledGroupShipment) {
    return <DefaultRowsIteratorComponent {...props} />;
  }
  return <ShipmentBundling {...props} layout={layout} />;
};

RowsIterator.propTypes = {
  enabledGroupShipment: PropTypes.bool.isRequired,
  DefaultRowsIteratorComponent: PropTypes.elementType.isRequired
};

export default connect((state, props) => {
  const is3PP = findRolesInUser([ROLES.labPartner], state.auth.user);
  const { layout } = state.patients;
  const shipmentBundlingEnabled = isShipmentBundlingEnabledSelector(state);
  return {
    enabledGroupShipment: is3PP ? false : shipmentBundlingEnabled,
    layout
  };
})(RowsIterator);
