import React, { useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { get, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FlawedTraysWorkflow from './FlawedTraysWorkflow';
import PrintingRequestWorkflowSection from './PrintingRequestWorkflowSection';
import { FLAWED_TRAYS_STATUSES_ID, REQUEST_STATUSES_ID } from '../../../constants';
import { cancelRequestAction, completeRequestAction } from '../../../reducers/requests';
import useRefresh from '../../../hooks/useRefresh';
import { formatProcesses } from '../../../helpers/techWorkflow';
import PrintCompletionDialog from '../../../components/Shipment/Dialogs/PrintCompletionDialog';

const useStyles = makeStyles(theme => ({
  requestHeader: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    alignItems: 'center'
  },
  requestsSetupHeaderLeft: {
    display: 'flex'
  },
  requestsSetupHeaderRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButton: {
    marginLeft: 'auto'
  }
}));

const RequestWorkflow = ({ request, iosFileId }) => {
  const classes = useStyles();
  const patient = useSelector(state => {
    return (
      state.patients.items[
        state.patients.items.findIndex(item => item.id === request.patient_id)
      ] || {}
    );
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const refresh = useRefresh(history, location.pathname);
  const selectedRequestId = request.id;

  const [openFormDialog, setOpenFormDialog] = useState(false);

  const flawedTrays = request && request.flawed_trays;

  const printingRequest = request && request.printing_request;

  const flawedRequestWorkflow = get(request, 'tech_workflow_flawed_tray', null);
  const printRequestWorkflow = get(
    request,
    'printing_request.tech_workflow_printing_request',
    null
  );

  const flawedRequestWorkflowData = useMemo(
    () => formatProcesses(get(flawedRequestWorkflow, 'processes', [])),
    [flawedRequestWorkflow]
  );

  const printRequestWorkflowData = useMemo(
    () => formatProcesses(get(printRequestWorkflow, 'processes', [])),
    [printRequestWorkflow]
  );

  const completeRequest = (params = {}) => {
    dispatch(completeRequestAction(selectedRequestId, params));
    setOpenFormDialog(false);
  };

  const handleEdit = () => {
    history.push(`/requests/${selectedRequestId}`);
  };

  const handleCancel = (params = {}) => {
    dispatch(cancelRequestAction(selectedRequestId, params)).then(response => {
      if (response) {
        refresh();
      }
    });
  };

  const handleComplete = () => {
    if (request && request.printing_request) {
      setOpenFormDialog(true);
    } else {
      completeRequest();
    }
  };

  return (
    <Grid container>
      <Box mt={2} width="100%">
        <Grid item xs={12}>
          <Grid container className={classes.request}>
            {!isEmpty(flawedTrays) && flawedRequestWorkflow && (
              <FlawedTraysWorkflow
                techWorkflowId={+flawedRequestWorkflow.tech_workflow_id}
                flawedTrays={flawedTrays}
                statusId={request.flawed_trays_status_id}
                statusUpdatedAt={request.flawed_trays_status_updated_at}
                requestId={selectedRequestId}
                patientId={request.patient_id}
                patient={patient}
                iosFileId={iosFileId}
                flawedRequestWorkflow={flawedRequestWorkflowData}
                showActionButtons={
                  [FLAWED_TRAYS_STATUSES_ID.new, FLAWED_TRAYS_STATUSES_ID.in_progress].indexOf(
                    request.flawed_trays_status_id
                  ) !== -1
                }
                handleCancel={handleCancel}
                handleComplete={completeRequest}
              />
            )}
            {!isEmpty(printingRequest) && (
              <PrintingRequestWorkflowSection
                techWorkflowId={printRequestWorkflow && +printRequestWorkflow.tech_workflow_id}
                printRequestWorkflow={printRequestWorkflowData}
                printingRequest={request.printing_request}
                patientId={request.patient_id}
                requestId={selectedRequestId}
                patient={patient}
                showActionButtons={request.status_id === REQUEST_STATUSES_ID.new}
                handleEdit={handleEdit}
                handleCancel={handleCancel}
                handleComplete={handleComplete}
              />
            )}
          </Grid>
        </Grid>
        <>
          <PrintCompletionDialog
            onClose={() => setOpenFormDialog(false)}
            open={openFormDialog}
            onContinue={completeRequest}
          />
        </>
      </Box>
    </Grid>
  );
};

RequestWorkflow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  request: PropTypes.object.isRequired,
  iosFileId: PropTypes.number.isRequired
};

RequestWorkflow.defaultProps = {};

export default RequestWorkflow;
