import React from 'react';
import { Checkbox } from '@material-ui/core';
import { checkNeededStep } from '../../../helpers/batching';

const Batching = ({ row, batchedCases, setBatchedCases }) => {
  const isBatched = batchedCases.findIndex(item => item.id === row.id) > -1;
  const hasFinishedTrayGeneration = checkNeededStep(row);

  const toggleCase = e => {
    e.stopPropagation();

    if (isBatched) {
      setBatchedCases(batchedCases.filter(item => item.id !== row.id));
    } else {
      setBatchedCases([
        ...batchedCases,
        {
          id: row.id,
          patientName: row.formatted_name,
          organizationName: `${row.organization.name}`,
          tray_generation_finished: hasFinishedTrayGeneration
        }
      ]);
    }
  };

  if (!hasFinishedTrayGeneration) {
    return '';
  }

  return (
    <>
      <Checkbox checked={isBatched} onChange={toggleCase} size="small" style={{ padding: 4 }} />
    </>
  );
};

Batching.propTypes = {};

export default Batching;
