import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const ConfirmationDialog = ({
  open,
  handleClose,
  title,
  bodyText,
  confirmText,
  declineText,
  confirmButtonProps,
  cancelButtonProps
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary" {...confirmButtonProps}>
          {declineText}
        </Button>
        <Button onClick={() => handleClose(true)} color="primary" autoFocus {...cancelButtonProps}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  confirmText: PropTypes.string,
  declineText: PropTypes.string,
  confirmButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object
};

ConfirmationDialog.defaultProps = {
  title: 'Alert',
  bodyText: 'Are you sure?',
  confirmText: 'Continue',
  declineText: 'Cancel',
  confirmButtonProps: {},
  cancelButtonProps: {}
};

export default ConfirmationDialog;
